.widget-header {
	background-color: var(--widget-header-bg-color);
	/*border: 1px solid var(--widget-border-color); */
	/* padding: 15px; */
	margin-bottom: 0;
	border-bottom: none;
	font-size: var(--h3-font-size);
	font-weight: 600;
	text-decoration: var(--h3-text-decoration);
	text-transform: var(--h3-text-transform);
	color: var(--widget-header-title-font-color);
	/*text-align: center;*/
	padding: 0 0 20px 0;
	color: #161616;
	font-size: 26px;
}

.widget-block {
	background-color: var(--widget-block-bg-color);
	padding: 0px;
	/*	border: 1px solid var(--widget-border-color);*/


	.title {
		font-size: var(--h4-font-size);
		font-weight: 400;
		text-decoration: var(--h3-text-decoration);
		text-transform: var(--h3-text-transform);
		color: var(--widget-block-title-font-color);
		margin-bottom: 1rem;
	}

	.unit-map {
		width: 100%;
		height: 380px;
		position: relative;
	}
}