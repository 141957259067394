.facility-page-layout-v1 {

	.h2,
	h2 {
		@media (max-width: 767px) {
			font-size: 30px;
		}
	}

	.title {

		padding-top: var(--page-margin-top);

		@media (max-width: 767px) {
			padding: 30px 15px 0 15px;
		}

		.heading1 {
			margin: 0px;

			@media (max-width: 767px) {
				font-size: 30px;
			}
		}

		.short-description {
			padding-bottom: 60px;
			font-size: 22px;
			color: var(--color-primary);

			@media (max-width: 767px) {
				padding-bottom: 30px;
				font-size: 22px;
			}
		}
	}


	.lage {
		padding-top: 60px;

		@media (max-width: 767px) {
			padding-top: 20px;
		}
	}

	.address {
		padding-bottom: 30px;
	}

	.page-container {

		@media(min-width:992px) {
			padding: 0px 15%;
		}
	}

	.description {
		padding-top: 60px;

		@media (max-width: 767px) {
			padding-top: 40px;
		}
	}


	.highlights {
		padding-top: 60px;

		@media (max-width: 767px) {
			padding-top: 40px;
		}

		.highlight-title {
			font-weight: 600;
			padding-bottom: 10px;
		}
	}

	.point {
		line-height: 26px;
		display: flex;

		.fas {
			color: var(--color-secondary);
			padding-right: 16px;
			vertical-align: top;
			padding-top: 5px;
		}
	}


	.facility-info {
		border: 1px solid var(--color-primary);
		padding: 20px;
		margin-top: 60px;
		display: flex;
		align-items: center;

		@media (max-width: 767px) {
			margin-top: 40px;
		}

		.house-icon {
			display: none;
			font-size: 82px;
			min-width: 200px;
			text-align: center;
			color: var(--color-primary);

			@media(min-width: 992px) {
				display: block;
			}
		}

		.house-info {

			@media(min-width: 992px) {
				border-left: 1px solid var(--color-primary);
				padding-left: 30px;

			}

			.name {
				color: var(--color-primary);
				font-size: 1.5rem;
				padding-bottom: 15px;
				font-size: 1.5rem;
				font-weight: 800;
			}
		}

		.info {
			li {
				line-height: 26px;
				display: flex;
			}

			.fas {
				color: var(--color-primary);
				padding-right: 15px;
				vertical-align: top;
				padding-top: 5px;
			}
		}
	}



	.facility-map {
		width: 100%;
		height: var(--facility-map-height);
		position: relative;
	}

	.units {
		padding-top: 60px;

		@media (max-width: 767px) {
			padding-top: 40px;
		}

		.col-lg-4 {
			display: block;

			&:nth-child(n + 7) {
				display: none;
			}
		}

		.heading1 {
			padding-bottom: 20px;

			@media (max-width: 767px) {
				padding-bottom: 0;
			}
		}
	}


}