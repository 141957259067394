.unit-quickbooker-v1 {

	margin-top: 20px;

	@media(min-width:1200px) {
		background-color: var(--color-white);
		display: block;
		border: 0px solid var(--color-grey-normal);
		background: #c6a372;

	}

	.price-table {
		background: transparent;

		td {
			color: #fff;
			font-size: 16px !important;
		}
	}



	h2 {
		font-size: 30px;
		font-weight: 600;
		color: #fff;
		margin-bottom: 15px;

	}

	.booking-hint {
		font-size: 13px;
		padding-bottom: 10px;
		margin-top: -10px;
	}

	.price-table {
		font-size: 0.88rem;
	}

	.unit-img {
		position: relative;

		.unit-code {
			position: absolute;
			left: 0px;
			bottom: 0px;
			background-color: var(--color-primary-light);
			padding: 2px 5px;
			color: var(--color-white);
			font-size: var(--font-size-md);
		}

		.fav-icon {
			position: absolute;
			right: 10px;
			top: -10px;
			color: #fff;
			cursor: pointer;
			background-color: var(--color-secondary);
			padding: 8px 10px;
		}
	}

	.info {
		padding: 30px 20px;
	}

	.search-bl {
		position: relative;

		i {
			position: absolute;
			left: 12px;
			top: 16px;
			color: var(--color-grey-dark);
		}
	}

	.form-control {
		text-align: left;
		margin-bottom: 15px;
		background-color: var(--color-white);
		font-size: var(--font-size-md);
		padding-left: 35px;
		min-height: 45px;
		border: none;
	}

	.table {
		td {
			border-top: 2px solid var(--color-white);
		}

		tbody {
			border-top: 2px solid var(--color-white);
		}
	}

	.optionals {
		margin-top: 20px;
	}

	.optionals-title {
		font-size: var(--h4-font-size);
		font-weight: var(--h4-font-weight);
		padding-left: 10px;
		color: #fff;
	}

	.consumptioncosts-title {
		font-size: var(--h5-font-size);
		font-weight: var(--h5-font-weight);
		padding-left: 10px;
	}

	.button-row {
		text-align: right;
		margin-top: 20px;

	}

	.subrow {
		margin-top: 20px;
	}
}