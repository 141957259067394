.unit-title-v1 {
	display: flex;

	@media (max-width: 767px) {
		justify-content: space-between;
	}


	.stars {
		vertical-align: top;
		display: flex;
		-webkit-box-align: center;
		align-items: center;
		color: #97c2e4;
		font-size: 12px;


	}

	.h2 {
		margin: 0px;
		line-height: normal;
		/* display: inline-block; */
		padding: 0 20px 13px 0;
		color: #161616;
		font-size: 30px;

		@media (max-width: 767px) {
			font-size: 26px;
		}
	}

	.col-12 {
		/* display: inline-block; */
		line-height: normal;
	}

	.rating {
		padding: 10px 0 15px 0;
		display: inline-block;

		span {
			font-size: 12px;
			font-weight: 400;
			color: #161616;
		}
	}
}