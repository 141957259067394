/* line 59, resources/assets/styles/components/_forms.scss */

#header-fastnav-search .form-inline,
#header-fastnav-search .search-form,
#header-fastnav-search-mobile .form-inline,
#header-fastnav-search-mobile .search-form {
	position: relative;
}

/* line 63, resources/assets/styles/components/_forms.scss */

#header-fastnav-search .input-group-text>span,
#header-fastnav-search-mobile .input-group-text>span {
	font-size: 1.5rem;
	color: #293771;
}

/* line 68, resources/assets/styles/components/_forms.scss */

#header-fastnav-search .form-control,
#header-fastnav-search .search-form .search-field,
.search-form #header-fastnav-search .search-field,
#header-fastnav-search-mobile .form-control,
#header-fastnav-search-mobile .search-form .search-field,
.search-form #header-fastnav-search-mobile .search-field {
	padding-right: 2rem;
}

/* line 72, resources/assets/styles/components/_forms.scss */

#header-fastnav-search button,
#header-fastnav-search-mobile button {
	color: #293771;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 10;
}

/* line 79, resources/assets/styles/components/_forms.scss */

#header-fastnav-search button>span,
#header-fastnav-search-mobile button>span {
	line-height: 0;
}

/* line 85, resources/assets/styles/components/_forms.scss */

.objectsearch {
	padding-top: 1rem;
	padding-bottom: 1rem;
}

@media (min-width: 768px) {
	/* line 85, resources/assets/styles/components/_forms.scss */

	.objectsearch {
		padding: 1rem;
		background-color: #fff;
	}
}

/* line 95, resources/assets/styles/components/_forms.scss */

.objectsearch .input-group * {
	border-color: #979797;
}

/**
 * Navigation toggles
 */

/* line 4, resources/assets/styles/components/_nav.scss */

.menu-toggle {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	height: calc(35px + 0.5rem);
	width: calc(35px + 0.5rem);
	padding: 0.5rem 0.5rem;
	border: none;
	background: transparent;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border-radius: 0.25rem;
}

@media (min-width: 992px) {
	/* line 4, resources/assets/styles/components/_nav.scss */

	.menu-toggle {
		margin-top: 1.5rem;
	}
}

/* line 21, resources/assets/styles/components/_nav.scss */

.menu-toggle .burger-text {
	color: #293771;
	margin-top: 5px;
	white-space: nowrap;
}

/* line 29, resources/assets/styles/components/_nav.scss */

.menu-toggle:not(.collapsed) .burger-lines span {
	opacity: 0;
}

/* line 32, resources/assets/styles/components/_nav.scss */

.menu-toggle:not(.collapsed) .burger-lines span:first-child {
	opacity: 1;
	-webkit-transform-origin: center;
	-o-transform-origin: center;
	transform-origin: center;
	-webkit-transform: translate3d(-4px, 10px, 0) rotate(45deg);
	transform: translate3d(-4px, 10px, 0) rotate(45deg);
	width: calc(35px + 0.5rem) !important;
}

/* line 38, resources/assets/styles/components/_nav.scss */

.menu-toggle:not(.collapsed) .burger-lines span:last-child {
	opacity: 1;
	-webkit-transform-origin: center;
	-o-transform-origin: center;
	transform-origin: center;
	-webkit-transform: translate3d(-4px, -8px, 0) rotate(-45deg);
	transform: translate3d(-4px, -8px, 0) rotate(-45deg);
	width: calc(35px + 0.5rem) !important;
}

/* line 49, resources/assets/styles/components/_nav.scss */

#menu-toggle-open {
	padding: 0.5rem 0.5rem;
}

/* line 52, resources/assets/styles/components/_nav.scss */

#menu-toggle-open .burger-lines {
	display: block;
	width: 35px;
}

/* line 56, resources/assets/styles/components/_nav.scss */

#menu-toggle-open .burger-lines span {
	display: block;
	height: 3px;
	width: 35px;
	background-color: #293771;
	border-radius: 0.25rem;
	-webkit-transition: 0.25s all ease-in-out;
	-o-transition: 0.25s all ease-in-out;
	transition: 0.25s all ease-in-out;
}

/* line 64, resources/assets/styles/components/_nav.scss */

#menu-toggle-open .burger-lines span:nth-child(2) {
	margin: 0.4rem 0;
}

/* line 72, resources/assets/styles/components/_nav.scss */

#menu-toggle-open:hover .burger-lines span+span {
	width: calc(35px * .75);
}

/* line 76, resources/assets/styles/components/_nav.scss */

#menu-toggle-open:hover .burger-lines span+span+span {
	width: calc(35px * .5);
}

/* line 83, resources/assets/styles/components/_nav.scss */

#menu-toggle-close {
	display: block;
	position: relative;
}

/* line 87, resources/assets/styles/components/_nav.scss */

#menu-toggle-close .cross-lines {
	display: block;
	position: relative;
	height: 3px;
	border-radius: 0.25rem;
	-webkit-transition: 0.25s -webkit-transform ease-in-out;
	transition: 0.25s -webkit-transform ease-in-out;
	-o-transition: 0.25s -o-transform ease-in-out;
	transition: 0.25s transform ease-in-out;
	transition: 0.25s transform ease-in-out, 0.25s -webkit-transform ease-in-out, 0.25s -o-transform ease-in-out;
}

/* line 94, resources/assets/styles/components/_nav.scss */

#menu-toggle-close .cross-lines:before,
#menu-toggle-close .cross-lines:after {
	display: block;
	content: '';
	width: 100%;
	height: 3px;
	background: #000;
	border-radius: 0.25rem;
	position: absolute;
	-webkit-transform-origin: center center;
	-o-transform-origin: center center;
	transform-origin: center center;
}

/* line 106, resources/assets/styles/components/_nav.scss */

#menu-toggle-close .cross-lines:before {
	-webkit-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
	top: 0;
}

/* line 111, resources/assets/styles/components/_nav.scss */

#menu-toggle-close .cross-lines:after {
	-webkit-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	transform: rotate(-45deg);
	bottom: 0;
}

/* line 118, resources/assets/styles/components/_nav.scss */

#menu-toggle-close:hover .cross-lines {
	-webkit-transform: scale(1.25);
	-o-transform: scale(1.25);
	transform: scale(1.25);
}

/**
 * Navbar
 */

/* line 7, resources/assets/styles/components/_nav-navbar.scss */

#navbar-menu .nav-item .nav-link {
	color: #fff;
}

/* line 10, resources/assets/styles/components/_nav-navbar.scss */

#navbar-menu .nav-item .nav-link.active {
	font-weight: bold;
}

/* line 14, resources/assets/styles/components/_nav-navbar.scss */

#navbar-menu .nav-item .nav-link:hover {
	color: blue;
}

/* line 20, resources/assets/styles/components/_nav-navbar.scss */

#navbar-menu .nav-item-has-children .nav-link {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

/* line 24, resources/assets/styles/components/_nav-navbar.scss */

#navbar-menu .nav-item-has-children .nav-link:after {
	vertical-align: unset;
	border-top: unset;
	border-right: unset;
	border-bottom: unset;
	border-left: unset;
	font-family: 'smi', sans-serif;
	content: "\EA05" !important;
	display: inline-block;
	font-size: 1rem;
	margin-left: .25rem;
	-webkit-transition: 0.25s -webkit-transform ease-in-out;
	transition: 0.25s -webkit-transform ease-in-out;
	-o-transition: 0.25s -o-transform ease-in-out;
	transition: 0.25s transform ease-in-out;
	transition: 0.25s transform ease-in-out, 0.25s -webkit-transform ease-in-out, 0.25s -o-transform ease-in-out;
}

/* line 39, resources/assets/styles/components/_nav-navbar.scss */

#navbar-menu .nav-item-has-children.show .nav-link:after {
	-webkit-transform: rotate(-180deg);
	-o-transform: rotate(-180deg);
	transform: rotate(-180deg);
}

/* line 45, resources/assets/styles/components/_nav-navbar.scss */

#navbar-menu .nav-item .dropdown-menu .has-children:hover>.dropdown-menu {
	opacity: 1;
	pointer-events: all;
}

/* line 50, resources/assets/styles/components/_nav-navbar.scss */

#navbar-menu .nav-item.dropdown-menu-sublevels-left .dropdown-menu:not(.level-0) {
	left: -100%;
}

/* line 54, resources/assets/styles/components/_nav-navbar.scss */

#navbar-menu .nav-item.dropdown-menu-sublevels-right .dropdown-menu:not(.level-0) {
	left: 100%;
}

/* line 58, resources/assets/styles/components/_nav-navbar.scss */

#navbar-menu .nav-item .dropdown-menu .dropdown-menu {
	display: block;
	top: -5px;
	opacity: 0;
	-webkit-transition: 0.25s opacity ease-in-out;
	-o-transition: 0.25s opacity ease-in-out;
	transition: 0.25s opacity ease-in-out;
	pointer-events: none;
}

/* line 68, resources/assets/styles/components/_nav-navbar.scss */

#navbar-menu .dropdown-menu {
	border-radius: 0;
	/**
         * In order for the js calculation to work, all .dropdown-menu must have the same width
         */
	width: 200px;
}

/* line 76, resources/assets/styles/components/_nav-navbar.scss */

#navbar-menu .dropdown-menu.-left {
	left: auto;
	right: 0;
}

/* line 81, resources/assets/styles/components/_nav-navbar.scss */

#navbar-menu .dropdown-menu li {
	position: relative;
}

/* line 85, resources/assets/styles/components/_nav-navbar.scss */

#navbar-menu .dropdown-menu .dropdown-item {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	white-space: normal;
}

/* line 92, resources/assets/styles/components/_nav-navbar.scss */

#navbar-menu .dropdown-menu .dropdown-item-has-children:after {
	font-family: 'smi', sans-serif;
	display: inline-block;
	font-size: 0.75rem;
	margin-left: 1rem;
	-webkit-transition: 0.25s -webkit-transform ease-in-out;
	transition: 0.25s -webkit-transform ease-in-out;
	-o-transition: 0.25s -o-transform ease-in-out;
	transition: 0.25s transform ease-in-out;
	transition: 0.25s transform ease-in-out, 0.25s -webkit-transform ease-in-out, 0.25s -o-transform ease-in-out;
}

/* line 100, resources/assets/styles/components/_nav-navbar.scss */

#navbar-menu .dropdown-menu .dropdown-item-has-children.has-children-left:after {
	content: "\EA03" !important;
}

/* line 104, resources/assets/styles/components/_nav-navbar.scss */

#navbar-menu .dropdown-menu .dropdown-item-has-children.has-children-right:after {
	content: "\EA04" !important;
}

/* line 111, resources/assets/styles/components/_nav-navbar.scss */

#navbar-menu .dropdown-menu .dropdown-item.active {
	font-weight: bold;
	background-color: #293771;
}

/* line 116, resources/assets/styles/components/_nav-navbar.scss */

#navbar-menu .dropdown-menu .dropdown-item:hover {
	color: red;
	background-color: #151c39;
}

/**
 * Fixed menu
 */

@media (max-width: 767.98px) {
	/* line 5, resources/assets/styles/components/_nav-fixed.scss */

	body.fixed-menu-open {
		overflow: hidden;
	}
}

/* line 11, resources/assets/styles/components/_nav-fixed.scss */

#fixed-menu {
	position: fixed;
	left: 0;
	right: 0;
	top: 75px;
	bottom: 0;
	z-index: 1000;
	background-color: #fff;
	overflow: scroll;
	padding-bottom: 5rem;
	/**
     * Navigation
     */
}

@media (min-width: 768px) {
	/* line 11, resources/assets/styles/components/_nav-fixed.scss */

	#fixed-menu {
		background-color: transparent;
		top: auto;
		bottom: unset;
		overflow: unset;
	}
}

/* line 29, resources/assets/styles/components/_nav-fixed.scss */

#fixed-menu>.container-md {
	background-color: #fff;
}

/* line 34, resources/assets/styles/components/_nav-fixed.scss */

#fixed-menu>.container-md nav>.row>div>.nav-link {
	font-weight: 600;
}

/* line 37, resources/assets/styles/components/_nav-fixed.scss */

#fixed-menu>.container-md nav>.row>div>.nav-link:hover {
	color: #C6A372;
}

/* line 42, resources/assets/styles/components/_nav-fixed.scss */

#fixed-menu>.container-md nav>.row>div>span.nav-link {
	color: #293771;
}

/* line 45, resources/assets/styles/components/_nav-fixed.scss */

#fixed-menu>.container-md nav>.row>div>span.nav-link:hover {
	color: #293771;
}

/* line 53, resources/assets/styles/components/_nav-fixed.scss */

#fixed-menu .submenu {
	list-style: none;
	padding-left: 0;
}

@media (max-width: 767.98px) {
	/* line 63, resources/assets/styles/components/_nav-fixed.scss */

	#fixed-menu #fixed-menu-nav>.row {
		margin: 0;
	}

	/* line 66, resources/assets/styles/components/_nav-fixed.scss */

	#fixed-menu #fixed-menu-nav .nav-item {
		border-bottom: 1px solid #97C2E4;
		padding: 1rem;
	}

	/* line 70, resources/assets/styles/components/_nav-fixed.scss */

	#fixed-menu #fixed-menu-nav .nav-item .nav-link {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		padding: 0;
	}

	/* line 75, resources/assets/styles/components/_nav-fixed.scss */

	#fixed-menu #fixed-menu-nav .nav-item .nav-link.active {
		font-weight: 600;
	}

	/* line 79, resources/assets/styles/components/_nav-fixed.scss */

	#fixed-menu #fixed-menu-nav .nav-item .nav-link:hover {
		color: #293771;
	}

	/* line 86, resources/assets/styles/components/_nav-fixed.scss */

	#fixed-menu #fixed-menu-nav .nav-item-has-children .nav-link:after {
		font-family: 'smi', sans-serif;
		content: "\EA09" !important;
		display: inline-block;
		font-size: 1rem;
		margin-left: .5rem;
		-webkit-transition: 0.25s -webkit-transform ease-in-out;
		transition: 0.25s -webkit-transform ease-in-out;
		-o-transition: 0.25s -o-transform ease-in-out;
		transition: 0.25s transform ease-in-out;
		transition: 0.25s transform ease-in-out, 0.25s -webkit-transform ease-in-out, 0.25s -o-transform ease-in-out;
	}

	/* line 95, resources/assets/styles/components/_nav-fixed.scss */

	#fixed-menu #fixed-menu-nav .nav-item-has-children .nav-link.show:after {
		-webkit-transform: rotate(-180deg);
		-o-transform: rotate(-180deg);
		transform: rotate(-180deg);
	}
}

/* line 103, resources/assets/styles/components/_nav-fixed.scss */

#fixed-menu #fixed-menu-nav .submenu-link {
	padding: 0.5rem 1rem;
}

/* line 109, resources/assets/styles/components/_nav-fixed.scss */

#fixed-menu #fixed-menu-nav .submenu {
	display: none;
	padding-left: 0;
	padding-top: 1rem;
}

@media (min-width: 768px) {
	/* line 109, resources/assets/styles/components/_nav-fixed.scss */

	#fixed-menu #fixed-menu-nav .submenu {
		padding: 0;
	}
}

/* line 119, resources/assets/styles/components/_nav-fixed.scss */

#fixed-menu #fixed-menu-nav .submenu .submenu-item .submenu-link {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

/* line 124, resources/assets/styles/components/_nav-fixed.scss */

#fixed-menu #fixed-menu-nav .submenu .submenu-item .submenu-link-has-children:after {
	font-family: 'smi', sans-serif;
	content: "\EA09" !important;
	display: inline-block;
	font-size: 1rem;
	margin-left: .5rem;
	-webkit-transition: 0.25s -webkit-transform ease-in-out;
	transition: 0.25s -webkit-transform ease-in-out;
	-o-transition: 0.25s -o-transform ease-in-out;
	transition: 0.25s transform ease-in-out;
	transition: 0.25s transform ease-in-out, 0.25s -webkit-transform ease-in-out, 0.25s -o-transform ease-in-out;
}

/* line 133, resources/assets/styles/components/_nav-fixed.scss */

#fixed-menu #fixed-menu-nav .submenu .submenu-item .submenu-link-has-children.show:after {
	-webkit-transform: rotate(-180deg);
	-o-transform: rotate(-180deg);
	transform: rotate(-180deg);
}

/* line 138, resources/assets/styles/components/_nav-fixed.scss */

#fixed-menu #fixed-menu-nav .submenu .submenu-item .submenu-link.active {
	font-weight: 600;
}

/* line 142, resources/assets/styles/components/_nav-fixed.scss */

#fixed-menu #fixed-menu-nav .submenu .submenu-item .submenu-link:hover {
	text-decoration: none;
	color: #C6A372;
}

/* line 151, resources/assets/styles/components/_nav-fixed.scss */

#fixed-menu .fixed-footer-meta-nav {
	position: fixed;
	bottom: 0rem;
	padding-bottom: 1rem;
	background-color: #fff;
}

/* line 157, resources/assets/styles/components/_nav-fixed.scss */

#fixed-menu .fixed-footer-meta-nav .menu-item {
	display: inline-block;
	margin-right: 0.25rem;
}

/* line 161, resources/assets/styles/components/_nav-fixed.scss */

#fixed-menu .fixed-footer-meta-nav .menu-item::before {
	content: '|';
	padding-right: 0.25rem;
}

/* line 167, resources/assets/styles/components/_nav-fixed.scss */

#fixed-menu .fixed-footer-meta-nav .menu-item:first-child::before {
	display: none;
}

/* line 172, resources/assets/styles/components/_nav-fixed.scss */

#fixed-menu .fixed-footer-meta-nav .menu-item.menunewline {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 100%;
	flex: 0 0 100%;
}

/* line 174, resources/assets/styles/components/_nav-fixed.scss */

#fixed-menu .fixed-footer-meta-nav .menu-item.menunewline::before {
	display: none;
}

/**
 * Layouts
 */

/* line 1, resources/assets/styles/layouts/_header.scss */

#header-main {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	background-color: #fff;
	height: 75px;
	padding: 1rem 0;
}

@media (min-width: 768px) {
	/* line 1, resources/assets/styles/layouts/_header.scss */

	#header-main {
		height: 110px;
		border-bottom: 1px solid #C6A372;
	}
}

@media (min-width: 992px) {
	/* line 1, resources/assets/styles/layouts/_header.scss */

	#header-main {
		height: 120px;
	}
}

/* line 16, resources/assets/styles/layouts/_header.scss */

#header-main>.container {
	position: relative;
}

/* line 21, resources/assets/styles/layouts/_header.scss */

#header-main.sticky-xs,
#header-main.sticky-sm,
#header-main.sticky-md,
#header-main.sticky-lg,
#header-main.sticky-xl {
	top: 0;
	z-index: 1020;
}

/* line 30, resources/assets/styles/layouts/_header.scss */

#header-main.sticky-xs {
	position: sticky;
}

@media (min-width: 576px) {
	/* line 34, resources/assets/styles/layouts/_header.scss */

	#header-main.sticky-sm {
		position: sticky;
	}
}

@media (min-width: 768px) {
	/* line 40, resources/assets/styles/layouts/_header.scss */

	#header-main.sticky-md {
		position: sticky;
	}
}

@media (min-width: 992px) {
	/* line 46, resources/assets/styles/layouts/_header.scss */

	#header-main.sticky-lg {
		position: sticky;
	}
}

@media (min-width: 1200px) {
	/* line 52, resources/assets/styles/layouts/_header.scss */

	#header-main.sticky-xl {
		position: sticky;
	}
}

/* line 61, resources/assets/styles/layouts/_header.scss */

.header-fastnav ul {
	list-style: none;
	margin: 0;
	padding: 0.75rem 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	font-size: 1.125rem;
	border-top: 1px solid #97C2E4;
	border-bottom: 1px solid #97C2E4;
}

@media (min-width: 992px) {
	/* line 61, resources/assets/styles/layouts/_header.scss */

	.header-fastnav ul {
		font-size: 1.5rem;
	}
}

@media (min-width: 768px) {
	/* line 61, resources/assets/styles/layouts/_header.scss */

	.header-fastnav ul {
		margin: 0;
		padding: 0;
		border: 0;
	}
}

/* line 76, resources/assets/styles/layouts/_header.scss */

.header-fastnav ul>li {
	padding: 0 0.5rem;
}

@media (min-width: 768px) {
	/* line 76, resources/assets/styles/layouts/_header.scss */

	.header-fastnav ul>li {
		border-right: 1px solid #979797;
	}
}

/* line 83, resources/assets/styles/layouts/_header.scss */

.header-fastnav ul>li:last-of-type {
	border-right: 0;
	padding-right: 0;
}

/* line 88, resources/assets/styles/layouts/_header.scss */

.header-fastnav ul>li a {
	border-radius: 50%;
	padding: 0.5rem;
}

/* line 92, resources/assets/styles/layouts/_header.scss */

.header-fastnav ul>li a:hover,
.header-fastnav ul>li a:focus {
	background-color: #97C2E4;
}

/* line 96, resources/assets/styles/layouts/_header.scss */

.header-fastnav ul>li a>span {
	text-align: center;
	width: 1.25em;
	vertical-align: bottom;
	display: inline-block;
}

/* line 106, resources/assets/styles/layouts/_header.scss */

.header-fastnav-modal {
	display: none;
	z-index: 100;
}

@media (min-width: 768px) {
	/* line 106, resources/assets/styles/layouts/_header.scss */

	.header-fastnav-modal {
		position: absolute;
		top: calc(100% + 1rem + 1px);
		right: 1rem;
		background-color: #fff;
		padding: 1rem;
	}
}

/* line 119, resources/assets/styles/layouts/_header.scss */

.header-fastnav-modal .d-flex>* {
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
	white-space: nowrap;
}

/* line 124, resources/assets/styles/layouts/_header.scss */

.header-fastnav-modal .d-flex span {
	padding-right: 1rem;
}

/* line 130, resources/assets/styles/layouts/_header.scss */

#header-fastnav-contact,
#header-fastnav-contact-mobile {
	color: #293771;
}

/* line 135, resources/assets/styles/layouts/_header.scss */

#header-fastnav-search-mobile,
#header-fastnav-contact-mobile {
	border-bottom: 1px solid #97C2E4;
	padding: 1rem 0;
}

/* line 141, resources/assets/styles/layouts/_header.scss */

.btn-offer-md {
	display: none;
}

@media (min-width: 768px) {
	/* line 141, resources/assets/styles/layouts/_header.scss */

	.btn-offer-md {
		display: inline-block;
		position: absolute;
		top: calc(100% + 1rem);
		right: 1rem;
	}

	/* line 150, resources/assets/styles/layouts/_header.scss */

	.btn-offer-md .btn-offer {
		padding: 0 2rem 0 0;
	}

	/* line 153, resources/assets/styles/layouts/_header.scss */

	.btn-offer-md .btn-offer>span {
		border-right: 1px solid #fff;
		line-height: 1;
		padding: 0.5rem 1rem;
		display: inline-block;
	}
}

/* line 164, resources/assets/styles/layouts/_header.scss */

.btn-offer-sm .btn-offer {
	margin: 0 -1rem;
	display: block;
}

@media (min-width: 768px) {
	/* line 163, resources/assets/styles/layouts/_header.scss */

	.btn-offer-sm {
		display: none;
	}
}

#header-fastnav-search,
#header-fastnav-search-mobile {

	.input-group-text {
		border-right: 0;
		background-color: #fff;
		height: 50px;
	}

	.form-control {
		height: 50px;
		border: 1px solid var(--color-primary);
		border-left: 0;
	}
}