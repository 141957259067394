.unit-page-layout-v1 {

	.unit-title-main {
		position: sticky;
		z-index: 14;
		top: 110px;
		text-align: left;
		margin-top: -30px;
		margin-bottom: 15px;
		background: #fff;

		@media (max-width: 992px) {
			position: static;
		}
	}

	.iframe-block {
		position: relative;
		width: 100%;
		overflow: hidden;
		aspect-ratio: 3 / 2;

		/* 1:1 Aspect Ratio */
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			width: 100%;
			height: 100%;
			border: none;
		}
	}



	.left-col {
		padding: 0px 15px;

		@media(max-width: 1200px) {
			width: 100% !important;
		}

		@media(min-width: 1201px) {
			width: 68% !important;
		}


		@media(min-width: 1500px) {
			width: 75% !important;
		}
	}

	.right-col {

		padding: 0px 15px;

		@media(max-width: 1200px) {
			display: none;
		}

		@media(min-width: 1201px) {
			width: 32% !important;
		}


		@media(min-width: 1500px) {
			width: 25% !important;
			padding-left: 5px;
		}
	}


	.booking-hint {
		font-size: var(--font-size-md);
		margin-top: -20px;
		padding-bottom: 10px;
	}

	.unit-book-now-v3 {
		@media(min-width:1200px) {
			background-color: var(--color-white);
			border: 1px solid var(--color-grey-normal);
			padding: 20px 15px;
			position: -webkit-sticky;
			position: sticky;
			z-index: 12;
			top: 237px;
			display: none;
			margin-bottom: 57px;
		}

		&.is-visible {
			@media(min-width:992px) {
				display: block;
			}
		}
	}

	.unit-book-now-v4 {
		@media(min-width:1200px) {
			background-color: var(--color-grey-light);
			padding: 20px 15px;
			position: -webkit-sticky;
			position: sticky;
			z-index: 12;
			top: 180px;
			margin-bottom: 57px;
		}

	}

	.unit-first-section {
		h4 {
			font-size: 22px;
			color: #161616;
			margin: 0 0 20px 0;
		}

		p {
			color: #161616;
			font-size: 16px;
		}
	}

	.unit-book-now-v4 {
		.btn-primary {
			background: #c6a372;
			border-color: #c6a372;
		}
	}

	.highlights {
		ul {
			display: flex;
			align-items: center;
			justify-content: space-around;
			margin: 30px 0 30px 0;
			border-bottom: 1px solid #ccc;
			padding-bottom: 40px;

			@media (max-width: 767px) {
				padding-bottom: 0;
				flex-wrap: wrap;
			}

			li {
				padding: 0 15px;
				color: #161616;
				font-size: 16px;

				@media (max-width: 767px) {
					width: 33.33%;
					margin-bottom: 40px;
					text-align: center;
					padding: 0 !important;
				}

				span {
					display: block;
					width: 100%;
					text-align: center;
					color: #c6a372;
					font-size: 26px;
					margin: 0 0 10px 0;

				}
			}
		}
	}

	.unit-map-v1 {
		border-bottom: 1px solid #ccc;
		padding-bottom: 60px;
	}

}