.unit-description-v1 {
	padding-bottom: 25px;
	border-bottom: 1px solid #ccc;
	margin-bottom: 50px;

	@media (max-width: 767px) {
		padding-bottom: 40px;
		margin-bottom: 40px;
	}

	.widget-block {
		display: none;
	}

	&.showFull {
		.widget-block {
			display: block;
		}

	}

	.widget-header {
		display: flex;
		padding: 0 0 0 0;
		color: #161616;
		text-decoration: none;

		.title1 {
			padding: 0 0 20px;
			color: #161616;
			font-size: 26px;
		}

		a {
			text-decoration: none;
			font-size: 16px;
		}

		span {
			display: flex;
			align-items: center;
			margin-left: 20px;
			font-size: 16px;
			padding-bottom: 20px;
		}
	}

	.description {
		margin-bottom: 30px;
	}

	.show-more {
		font-size: var(--font-size-md);

		&:after {
			content: "\f107";
			margin-left: 10px;
			margin-left: 5px;
			font-family: fontawesome;
		}
	}

	.show-less {
		font-size: var(--font-size-md);

		&:after {
			content: "\f106";
			margin-left: 10px;
			margin-left: 5px;
			font-family: fontawesome;
		}
	}

	.title {
		font-weight: 700;
		font-size: 18px;
		color: #161616;
		display: block;
		margin: 0 0 10px 0;

	}
}