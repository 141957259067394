.unit-pricelist-v1 {
	padding-bottom: 45px;
	border-bottom: 1px solid #ccc;

	@media (max-width: 767px) {
		padding-bottom: 25px;
		margin-top: -20px;
	}

	.unit-section {
		margin: 0;
	}

	#pricelist {}

	.widget-block {
		display: none;
	}

	.showFull {
		.widget-block {
			display: block;
		}
	}

	.widget-header {
		display: flex;
		padding: 0;
		color: #161616;
		text-decoration: none;

		.title1 {
			padding: 0 0 20px;
			color: #161616;
			font-size: 26px;
		}

		a {
			text-decoration: none;
			font-size: 16px;
		}

		span {
			display: flex;
			align-items: center;
			padding-bottom: 15px;
			margin-left: 20px;
			font-size: 16px;
		}
	}

	a {}


	.pricelist {


		.mainrow {

			background-color: var(--color-grey-light);

			td {
				font-weight: 500;

				@media (max-width:420px) {
					font-size: 0.9rem;
				}
			}
		}

		.subrow {

			td {
				vertical-align: bottom;

				@media (max-width:420px) {
					font-size: 0.9rem;
				}
			}
		}

		.td1 {
			width: 30%;
		}

		.td2 {
			width: 30%;
		}

		.td3 {
			width: 15%;
		}

		.td4 {
			width: 10%;
		}

		.td5 {
			width: 15%;
		}
	}

	.nav-tabs {
		border-bottom: 0px solid #dee2e6;

		.nav-link {
			margin-right: 1px;
			background: var(--color-grey-light);
			border-top-left-radius: 0;
			border-top-right-radius: 0;
			padding: 10px 30px;

			&.active {
				background: transparent;
			}
		}
	}
}