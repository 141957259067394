/* source-sans-pro-regular - latin */
@font-face {
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 400;
	src: url('RESOURCE/fonts/source-sans-pro-v21-latin-regular.eot');
	/* IE9 Compat Modes */
	src: local(''),
		url('RESOURCE/fonts/source-sans-pro-v21-latin-regular.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */
		url('RESOURCE/fonts/source-sans-pro-v21-latin-regular.woff2') format('woff2'),
		/* Super Modern Browsers */
		url('RESOURCE/fonts/source-sans-pro-v21-latin-regular.woff') format('woff'),
		/* Modern Browsers */
		url('RESOURCE/fonts/source-sans-pro-v21-latin-regular.ttf') format('truetype'),
		/* Safari, Android, iOS */
		url('RESOURCE/fonts/source-sans-pro-v21-latin-regular.svg#SourceSansPro') format('svg');
	/* Legacy iOS */
	font-display: swap;
}

/* source-sans-pro-600 - latin */
@font-face {
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 600;
	src: url('RESOURCE/fonts/source-sans-pro-v21-latin-600.eot');
	/* IE9 Compat Modes */
	src: local(''),
		url('RESOURCE/fonts/source-sans-pro-v21-latin-600.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */
		url('RESOURCE/fonts/source-sans-pro-v21-latin-600.woff2') format('woff2'),
		/* Super Modern Browsers */
		url('RESOURCE/fonts/source-sans-pro-v21-latin-600.woff') format('woff'),
		/* Modern Browsers */
		url('RESOURCE/fonts/source-sans-pro-v21-latin-600.ttf') format('truetype'),
		/* Safari, Android, iOS */
		url('RESOURCE/fonts/source-sans-pro-v21-latin-600.svg#SourceSansPro') format('svg');
	/* Legacy iOS */
	font-display: swap;
}

@font-face {
	font-family: "smi";
	src: url('RESOURCE/fonts/smi_f1cd72c0.eot');
	/* IE9*/
	src: url(RESOURCE/fonts/smi.eot) format("embedded-opentype"),
		url('RESOURCE/fonts/smi_295335ab.woff2') format("woff2"),
		url('RESOURCE/fonts/smi_34f7916a.woff') format("woff"),
		url('RESOURCE/fonts/smi_ebc93a71.ttf') format("truetype"),
		url('RESOURCE/fonts/smi_e72f8ad4.svg') format("svg");
	font-display: swap;
}