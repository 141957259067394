.search-page-layout-v3 {
	background-color: #f4f4f4;
	padding-top: var(--page-margin-top);
	padding-bottom: 30px;

	@media (max-width: 767px) {
		padding-top: 30px;
	}

	.search-token-down {
		display: none;

		@media (max-width: 767px) {
			display: block;
			padding: 0 15px;
			width: 100%;
		}
	}

	.search-header {
		h2 {
			font-size: 36px;

			@media (max-width: 767px) {
				font-size: 26px;
			}
		}

		.search-tocken {
			@media (max-width: 767px) {
				display: none;
			}
		}
	}

	.search-mob-filter {
		display: none;

		@media (max-width: 767px) {
			display: flex;
			-webkit-box-align: center;
			align-items: center;
			-webkit-box-pack: justify;
			justify-content: space-between;
			padding: 0 15px;
			margin-bottom: 20px;
		}

		a {
			border-right: 1px solid var(--color-grey-dark);
			padding-right: 8px;
			margin-right: 5px;
			margin-bottom: 0;
			color: var(--color-secondary);
			position: relative;
			top: 0;
			font-size: 14px;
		}
	}

	.results {
		display: grid;
		grid-template-columns: 1fr;
		grid-row-gap: 30px;

		@media (min-width:1200px) {
			grid-template-columns: 1fr;
			column-gap: 30px;
		}

		/*	@media (max-width:992px) {
			grid-template-columns: 1fr 1fr;
			column-gap: 30px;
		} */

		@media (max-width:992px) {
			grid-template-columns: 1fr;
		}

	}

	.alert-info {
		background-color: var(--color-white);
	}

	.more-btn-panel {
		margin-top: 30px;
	}

	.container {
		/* @media (min-width:1200px) {
			max-width: 1200px;
		} */

		/* @media (min-width:1400px) {
			max-width: 1370px;
		} */
	}

}

.my-search-token-v2 {
	margin: 2px;
	padding: 2px 6px;
	display: inline-block;
	border: 1px solid var(--color-grey-normal);
	border-radius: 0px;
	background-color: var(--color-grey-light);
	font-size: 14px;
	cursor: pointer;
	position: relative;
	transition: all 0.5s ease;
	margin-right: 8px;
	background: #fff;

	.close {
		transition: all 0.5s ease;
		position: absolute;
		height: 13px;
		width: 13px;
		right: -7px;
		top: -7px;
		font-size: 14px;
		text-align: center;
		background-color: var(--color-primary);
		color: #fff;
		border-radius: 50%;
		font-weight: 400;
		line-height: 2px;
		opacity: 0;
	}

	&:hover {
		border: 1px solid var(--color-primary);
		background-color: var(--color-white);

		.close {
			opacity: 1;
		}
	}
}