.unit-room-details-v1 {
	.room {
		font-weight: 600;

		@media (max-width:992px) {
			font-size: var(--font-size-md);
		}

	}

	.room-details {
		ul {

			li {
				padding-left: 30px;
				position: relative;

				@media (max-width:992px) {
					font-size: var(--font-size-md);
				}


				&:before {
					content: "\f00c";
					font-family: "Font Awesome 5 Pro";
					-webkit-font-smoothing: antialiased;
					font-weight: 600;
					display: inline-block;
					position: absolute;
					left: 0;
					top: 0;
					color: var(--color-primary);
				}
			}
		}
	}
}