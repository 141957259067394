@import "mixin.css";

.layout-column {
	@mixin flexlayout column;
}

.layout-row {
	@mixin flexlayout row;
}

.flex {
	@mixin flex;
}

.size100 {
	@mixin size100;
}


html,
body {
	font-family: var(--font-family-main);
	color: var(--font-color-main);
	font-size: var(--font-size-main);
	font-weight: var(--font-weight-main);

}

h1,
.h1 {
	font-family: var(--h1-font-family);
	font-weight: var(--h1-font-weight);
	font-size: var(--h1-font-size);
	color: var(--h1-font-color);
	text-transform: var(--h1-text-transform);
	text-decoration: var(--h1-text-decoration);

}

.h1-width-small {
	line-height: var(--h1-font-size);

	small {
		font-size: var(--font-size-main);
		text-transform: none;
		display: inline-block;
	}
}

h2,
.h2 {
	font-family: var(--h2-font-family);
	font-weight: var(--h2-font-weight);
	font-size: var(--h2-font-size);
	color: var(--h2-font-color);
	text-transform: var(--h2-text-transform);
	text-decoration: var(--h2-text-decoration);
}

h3,
.h3 {
	font-family: var(--h3-font-family);
	font-weight: var(--h3-font-weight);
	font-size: var(--h3-font-size);
	color: var(--h3-font-color);
	text-transform: var(--h3-text-transform);
	text-decoration: var(--h3-text-decoration);
}

h4,
.h4 {
	font-family: var(--h4-font-family);
	font-weight: var(--h4-font-weight);
	font-size: var(--h4-font-size);
	color: var(--h4-font-color);
	text-transform: var(--h4-text-transform);
	text-decoration: var(--h4-text-decoration);
}

h5,
.h5 {
	font-family: var(--h5-font-family);
	font-weight: var(--h5-font-weight);
	font-size: var(--h5-font-size);
	color: var(--h5-font-color);
	text-transform: var(--h5-text-transform);
	text-decoration: var(--h5-text-decoration);
}

.heading1 {
	display: block;
	border-bottom: 1px solid var(--heading1-border-color);
	margin-bottom: var(--heading1-margin-bottom);
	text-transform: var(--heading1-text-transform);
	padding-bottom: 10px;
	text-align: center;
}

a {
	color: var(--link-font-color);
	text-decoration: var(--link-text-decoration);

	&:hover {
		text-decoration: var(--link-text-decoration-hover);
		color: var(--link-font-color-hover);
	}
}

ul {
	padding: 0;
	margin: 0 0 15px;
	list-style: none;
}

.form-control:focus {
	box-shadow: none;
}

.btn:focus,
.btn:active {
	box-shadow: none;
}

/*--- background-colors---*/

.bg-primary {
	background-color: var(--color-primary);
}

.bg-primary-light {
	background-color: var(--color-primary-light);
}

.bg-secondary {
	background-color: var(--color-secondary);
}

.bg-secondary-light {
	background-color: var(--color-secondary-light);
}

.bg-white {
	background-color: var(--color-white);
}

.bg-grey-light {
	background-color: var(--color-grey-light);
}

.bg-grey-normal {
	background-color: var(--color-grey-normal);
}

.bg-grey-dark {
	background-color: var(--color-grey-dark);
}

.bg-black {
	background-color: var(--color-black);
}

/*--- font-colors---*/

.color-primary {
	color: var(--color-primary) !important;
}

.color-primary-light {
	color: var(--color-primary-light) !important;
}

.color-secondary {
	color: var(--color-secondary) !important;
}

.color-secondary-light {
	color: var(--color-secondary-light) !important;
}

.color-white {
	color: var(--color-white) !important;
}

.color-grey-light {
	color: var(--color-grey-light) !important;
}

.color-grey-normal {
	color: var(--color-grey-normal) !important;
}

.color-grey-dark {
	color: var(--color-grey-dark) !important;
}



.app-loaded #preloader {
	display: none;
}

.app-leaving #preloader {
	display: block;
	opacity: 0.6;
}

/*--- Datepicker ---*/

.datepicker-trigger {

	.asd__day--disabled.asd__day--in-range {
		background: var(--cal-selection-range) !important;
		opacity: 1 !important;
		border: 1px double var(--cal-selected-border) !important;
	}

	.asd__day--enabled.asd__day--in-range {
		background: var(--cal-selection-range) !important;
		color: var(--cal-text-color-selected) !important;
		opacity: 1 !important;
		border: 1px double var(--cal-selected-border) !important;
	}


	.asd__day--disabled.asd__day--hovered {
		background: var(--cal-selection-range) !important;
		opacity: 1 !important;
		border: 1px double var(--cal-selected-border) !important;
	}

	.asd__day--enabled.asd__day--hovered {
		background: var(--cal-selection-range) !important;
		opacity: 1 !important;
		border: 1px double var(--cal-selected-border) !important;
	}

	.asd__day--enabled {
		background-color: var(--cal-available) !important;
		color: var(--cal-text-color) !important;

		&.asd__day--disabled {
			background-color: var(--cal-disabled) !important;
			opacity: 0.8 !important;
			text-decoration: underline;

			&.asd__day--selected {
				background-color: var(--cal-selected) !important;
			}

			&.asd__day--in-range {
				background: var(--cal-selection-range) !important;
				opacity: 1 !important;
				border: 1px double var(--cal-selected-border) !important;
			}

			/*	&.asd__day--hovered {
				background: var(--cal-selection-range) !important;
				opacity: 1 !important;
				border: 1px double var(--cal-selected-border) !important;
			} */
		}


	}

	.asd__day--enabled:not(.asd__day--disabled) {
		&:hover {
			background: var(--cal-selected) !important;
			opacity: 1 !important;
			color: var(--cal-text-color-hover) !important;
		}
	}

	.asd__day--selected {
		background: var(--cal-selected) !important;
		color: var(--cal-text-color-selected) !important;
		opacity: 1 !important;
		border: 1px double var(--cal-selected-border) !important;

		&.asd__selected-date-one {
			position: relative;

			&:before {
				content: "";
				position: absolute;
				border-left: 12px solid transparent;
				border-top: 19px solid var(--cal-selection-range);
				border-bottom: 20px solid var(--cal-selection-range);
				right: 0px;
			}
		}

		&.asd__selected-date-two {
			position: relative;

			&:before {
				content: "";
				position: absolute;
				border-right: 12px solid transparent;
				border-top: 19px solid var(--cal-selection-range);
				border-bottom: 20px solid var(--cal-selection-range);
				left: 0px;
			}
		}

	}
}

/*--- Buttons ---*/



.btn {
	display: inline-block;
	font-weight: 400;
	color: #212529;
	cursor: pointer;
	text-align: center;
	vertical-align: middle;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	background-color: transparent;
	border: 1px solid transparent;
	padding: 0.75rem 0.75rem;
	font-size: 1rem;
	line-height: 1.5;
	border-radius: 0;
	-webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
	-o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;

	&.btn-primary,
	&.btn-go {
		background-color: var(--btn-primary-bg-color);
		color: var(--btn-primary-font-color);
		border-color: var(--btn-primary-border-color);

		&:hover {
			background-color: var(--btn-primary-bg-color-hover);
			border-color: var(--btn-primary-border-color-hover);
			color: var(--btn-primary-font-color-hover);
		}
	}

	&.btn-secondary {
		background-color: var(--btn-secondary-bg-color);
		color: var(--btn-secondary-font-color);
		border-color: var(--btn-secondary-border-color);

		&:hover {
			background-color: var(--btn-secondary-bg-color-hover);
			border-color: var(--btn-secondary-border-color-hover);
			color: var(--btn-secondary-font-color-hover);
		}
	}

	&.btn-default {
		background-color: var(--btn-default-bg-color);
		color: var(--btn-default-font-color);
		border-color: var(--btn-default-border-color);

		&:hover {
			background-color: var(--btn-default-bg-color-hover);
			border-color: var(--btn-default-border-color-hover);
			color: var(--btn-default-font-color-hover);
		}
	}

	&.btn-dark {
		background-color: var(--color-grey-dark);
		color: var(--color-white);
		border-color: var(--color-grey-dark));

		&:hover {
			background-color: #030303;
			border-color: black;
			color: var(--color-white);
		}
	}

	&.btn-add {
		background-color: var(--btn-add-bg-color);
		color: var(--btn-add-font-color);
		border-color: var(--btn-add-border-color);

		&:hover {
			background-color: var(--btn-add-bg-color-hover);
			border-color: var(--btn-add-border-color-hover);
			color: var(--btn-add-font-color-hover);
		}
	}

	&.btn-remove {
		background-color: var(--btn-remove-bg-color);
		color: var(--btn-remove-font-color);
		border-color: var(--btn-remove-border-color);

		&:hover {
			background-color: var(--btn-remove-bg-color-hover);
			border-color: var(--btn-remove-border-color-hover);
			color: var(--btn-remove-font-color-hover);
		}
	}

	&.btn-bc {
		background-color: var(--btn-breadcrumb-bg-color);
		color: var(--btn-breadcrumb-font-color);
		border-color: var(--btn-breadcrumb-border-color);

		&:hover {
			background-color: var(--btn-breadcrumb-bg-color-hover);
			border-color: var(--btn-breadcrumb-border-color-hover);
			color: var(--btn-breadcrumb-font-color-hover);
		}
	}

	&.btn-sm {
		font-size: var(--font-size-sm);
		padding: 6px 12px;
	}

	&.btn-lg {
		height: 46px;
		font-size: var(--font-size-lg);
		padding: 10px 16px;
	}

	&.big-pad {
		padding: 8px 30px;
	}

	&:focus {
		outline: 0 !important;
		text-decoration: none !important;
	}

	&.btn-arrow {
		position: relative;

	}

	&.btn-arrow::before {
		font-family: 'smi' !important;
		font-size: 16px;
		font-style: normal;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		content: '\EA05';
		vertical-align: top;
		display: inline-block;
		width: 1rem;
		opacity: 0;
		margin-left: -1rem;
		-webkit-transition: opacity 0.5s ease, width 0.5s ease 0.25s;
		-o-transition: opacity 0.5s ease, width 0.5s ease 0.25s;
		transition: opacity 0.5s ease, width 0.5s ease 0.25s;
	}


	&.btn-arrow:hover::before,
	.btn-arrow:focus::before {
		width: 2rem;
		opacity: 1;
		-webkit-transition: width 0.5s ease, opacity 0.5s ease 0.25s;
		-o-transition: width 0.5s ease, opacity 0.5s ease 0.25s;
		transition: width 0.5s ease, opacity 0.5s ease 0.25s;
	}
}

.btn-gold {
	background-color: #C6A372;
	color: #fff;
}

/* line 121, resources/assets/styles/components/_buttons.scss */

.btn-gold:hover,
.btn-gold:focus {
	background-color: #DEC2A0;
	color: #fff;
}

.btn-offer {
	font-weight: 600;
	text-align: left;
	position: relative;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}

/* line 135, resources/assets/styles/components/_buttons.scss */

.btn-offer>span {
	font-size: 2rem;
	margin-right: 0.5rem;
	vertical-align: middle;
	line-height: 1;
}

/* line 142, resources/assets/styles/components/_buttons.scss */

/*.btn-offer [class^="smi-"],
.btn-offer [class*="smi-"] {
	font-size: 1rem;
	vertical-align: text-bottom;
	position: absolute;
	top: 50%;
	right: 1rem;
	-webkit-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
} */

@media (min-width: 768px) {
	/* line 128, resources/assets/styles/components/_buttons.scss */

	.btn-offer {
		padding-left: 1rem;
		padding-right: 1rem;
	}

	/* line 155, resources/assets/styles/components/_buttons.scss */

	.btn-offer>span {
		margin-right: 1rem;
	}
}

.breadcrumb {
	background-color: #fff !important;
	padding-left: 0px;
	padding-bottom: 0;

}

.btn-breadcrumb .btn:not(:last-child):after {
	content: " ";
	display: block;
	width: 0;
	height: 0;
	border-top: 17px solid transparent;
	border-bottom: 17px solid transparent;
	border-left: 10px solid white;
	position: absolute;
	top: 50%;
	margin-top: -17px;
	left: 100%;
	z-index: 3;
}

.btn-breadcrumb .btn:not(:last-child):before {
	content: " ";
	display: block;
	width: 0;
	height: 0;
	border-top: 17px solid transparent;
	border-bottom: 17px solid transparent;
	border-left: 10px solid rgb(173, 173, 173);
	position: absolute;
	top: 50%;
	margin-top: -17px;
	margin-left: 1px;
	left: 100%;
	z-index: 3;
}

.btn-breadcrumb .btn {
	padding: 6px 12px 6px 24px;

	&.disabled {
		opacity: 0.6;
		color: #000;

	}
}

.btn-breadcrumb .btn:first-child {
	padding: 6px 6px 6px 10px;
}

.btn-breadcrumb .btn:last-child {
	padding: 6px 18px 6px 24px;
}

.btn-breadcrumb .btn.btn-bc:not(:last-child):after {
	border-left: 10px solid var(--btn-breadcrumb-bg-color);
}

.btn-breadcrumb .btn.btn-bc:not(:last-child):before {
	border-left: 10px solid var(--btn-breadcrumb-bg-color);
}

.btn-breadcrumb .btn.btn-bc:hover:not(:last-child):after {
	border-left: 10px solid var(--btn-breadcrumb-bg-color-hover);
}

.btn-breadcrumb .btn.btn-bc:hover:not(:last-child):before {
	border-left: 10px solid var(--btn-breadcrumb-bg-color-hover);
}



.breadcrumb li a:hover:after {
	border-left-color: #ffc107 !important;
}

.next-prev-btns {
	position: relative;

	.owl-nav {
		position: absolute;
		top: 40%;
		left: 0;
		width: 100%;

		@media (max-width:576px) {
			top: 20%;
		}




		button {
			width: 40px;
			height: 40px;
			border-radius: 50%;
			border-radius: 50%;
			box-shadow: 0 0 8px rgba(0, 0, 0, .21);
			background-color: var(--color-primary) !important;
			position: absolute;
			left: 0;

			@media (max-width:991px) and (min-width:600px) {
				left: -30px;
			}

			@media (min-width:1060px) {
				left: -50px;
			}


			span {
				font-family: "Font Awesome 5 Pro";
				font-weight: 900;
				-webkit-font-smoothing: antialiased;
				display: inline-block;
				font-style: normal;
				font-variant: normal;
				text-rendering: auto;
				line-height: 1;
				color: transparent;

				&:before {
					color: var(--color-white);
					font-family: "Font Awesome 5 Pro";
					position: relative;
					left: 2px;
					font-size: 16px;
					top: 1px;
				}
			}

			&.owl-next {
				position: absolute;
				right: 0;
				left: initial;

				@media (max-width:991px) and (min-width:600px) {
					right: -30px;
				}

				@media (min-width:1060px) {
					right: -50px;
				}

				/*	@media (max-width:992px) {
					right: -6px;
				} */

				span {

					&:before {
						content: "\f054";
						left: 3px;
					}
				}
			}

			&.owl-prev {
				span {

					&:before {
						content: "\f053";

					}
				}
			}
		}
	}
}

.flex-align-center {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.single-unit {
	margin: 0 auto 10px;
	max-width: 500px;
	transition: all 0.25s ease 0s;
}

.owl-carousel.off {
	display: block;
}

.payment-view {
	margin-top: var(--page-margin-top);
	padding-top: 30px;
	padding-bottom: 60px;
}

.inner-banner {
	background-image: url(RESOURCE/img/banner-4.jpg);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 290px;
	position: relative;

	@media (max-width:992px) {
		height: 220px;
	}


	.page-title {
		position: absolute;
		bottom: 20px;
		width: 100%;
		left: 0;
		z-index: 2;
		margin: 0;
		border-bottom: none;
		text-align: right;

		@media (max-width:992px) {
			bottom: 30px;
		}


		.pag-title {
			display: inline-block;
			font-size: 36px;
			margin-bottom: 0;
			color: var(--color-white);
			text-transform: uppercase;
			padding: 0 10px;
			border-bottom: 1px solid var(--color-white);

			@media (max-width:992px) {
				font-size: 24px;
			}

			span {
				text-shadow: 0 0.1rem 1rem rgba(0, 0, 0, .5);
			}
		}


	}
}

.vertical-center {
	margin: 0;
	position: absolute;
	top: 50%;
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

.googleMapsHint {
	color: var(--googlemaps-hint-text-color);
	font-size: var(--googlemaps-hint-font-size);
	width: 100%;
}

.no-google-map {
	width: 100%;
	height: 150px;
	position: relative;
}

.box {
	position: absolute;
	z-index: 999 !important;
}

.datepicker-ext-row {
	display: flex;
	align-items: center;
	margin: 20px 0 0 0 !important;

	@media (max-width: 767px) {
		flex-wrap: wrap;
		max-width: 280px;
		justify-content: center;
		width: 100%;
		margin: 30px auto 0 !important;
	}

	span {
		font-size: 15px;

		@media (max-width: 767px) {
			margin: 0 0 15px 0;
			max-width: 90px;
			display: inline-block;
			width: 100%;
		}
	}

	select {
		height: 40px;
		max-width: 130px;
		width: 100%;
		border-radius: 0;
		padding-left: 5px;
		padding-right: 5px;
		margin: 0 20px 0 10px;
		border: 1px solid rgba(0, 0, 0, .2);
		background-position: 96% 16px;

		@media (max-width: 767px) {
			margin: 0 20px 10px 10px;
			max-width: 160px;
		}

		@media all and (min-width: 400px) and (max-width: 480px) {
			max-width: 180px;
		}
	}
}

/*@media (min-width:1200px) {
	.container {
		max-width: 1230px;
	}
}*/

@media (min-width:1500px) {
	.container {
		max-width: 1370px;
	}
}

.pswp__caption__center {
	text-align: center !important;
}

.hide-mob {
	display: block;

	@media (max-width:992px) {
		display: none;
	}
}

.show-mob {
	display: none;

	@media (max-width:992px) {
		display: block;
	}
}

.fal {
	font-family: 'Font Awesome 5 Pro' !important;
	font-weight: 300;
}

.far {
	font-family: 'Font Awesome 5 Pro' !important;
	font-weight: 400;

}

.fas {
	font-family: 'Font Awesome 5 Pro' !important;
	font-weight: 400;

}

.covid-section {

	margin: 0;
	position: absolute;
	bottom: 40px;
	right: 40px;
	z-index: 2;


	@media (max-width: 1140px) {
		right: 20px;
		top: 120px;
		bottom: inherit;
	}

	@media (max-width: 768px) {
		right: 20px;
		top: 129px;
		bottom: inherit;
	}

	@media (max-width: 560px) {
		right: 10px;
		top: 129px;
		bottom: inherit;
	}


	.btn {
		width: 160px;
		height: 160px;
		border-radius: 100px;

		@media (max-width:1170px) {
			width: 130px;
			height: 130px;
			white-space: unset;
		}

		@media (max-width: 992px) {
			width: 110px;
			height: 110px;
			font-size: 14px;

		}

		@media (max-width: 768px) {
			width: 98px;
			height: 98px;
			font-size: 13px;

		}
	}


}

.modal-dialog {
	max-width: 800px;
	margin: 30px auto;
	width: 100%;
}

.alert {
	border-radius: 0px;
}

.alert-info {
	color: var(--color-primary);
	background-color: var(--color-primary-light);
	border-color: var(--color-primary);
}

/* line 1, resources/assets/styles/components/_icons.scss */
[class^="smi-"],
[class*=" smi-"] {
	font-size: unset;
	font-family: inherit;
}

/* line 6, resources/assets/styles/components/_icons.scss */
[class^="smi-"]::before,
[class*=" smi-"]::before {
	font-family: "smi", serif;
	font-weight: normal;
	font-style: normal;
	speak: never;
	display: inline-block;
	text-decoration: inherit;
	text-align: center;
	font-variant: normal;
	text-transform: none;
	line-height: 1em;
	vertical-align: middle;
}

/* line 18, resources/assets/fonts/sm-icon-font/smi.scss */

.smi-angle-down-regular:before {
	content: "\EA01";
}

/* line 19, resources/assets/fonts/sm-icon-font/smi.scss */

.smi-angle-left-regular:before {
	content: "\EA02";
}

/* line 20, resources/assets/fonts/sm-icon-font/smi.scss */

.smi-angle-right-regular:before {
	content: "\EA03";
}

/* line 21, resources/assets/fonts/sm-icon-font/smi.scss */

.smi-angle-up-regular:before {
	content: "\EA04";
}

/* line 22, resources/assets/fonts/sm-icon-font/smi.scss */

.smi-arrow-right-regular:before {
	content: "\EA05";
}

/* line 23, resources/assets/fonts/sm-icon-font/smi.scss */

.smi-calendar-days-regular:before {
	content: "\EA06";
}

/* line 24, resources/assets/fonts/sm-icon-font/smi.scss */

.smi-chevron-down-regular:before {
	content: "\EA07";
}

/* line 25, resources/assets/fonts/sm-icon-font/smi.scss */

.smi-chevron-left-regular:before {
	content: "\EA08";
}

/* line 26, resources/assets/fonts/sm-icon-font/smi.scss */

.smi-chevron-right-regular:before {
	content: "\EA09";
}

/* line 27, resources/assets/fonts/sm-icon-font/smi.scss */

.smi-chevron-up-regular:before {
	content: "\EA0A";
}

/* line 28, resources/assets/fonts/sm-icon-font/smi.scss */

.smi-envelope-regular:before {
	content: "\EA0B";
}

/* line 29, resources/assets/fonts/sm-icon-font/smi.scss */

.smi-facebook-brands:before {
	content: "\EA0C";
}

/* line 30, resources/assets/fonts/sm-icon-font/smi.scss */

.smi-google-plus-g-brands:before {
	content: "\EA0D";
}

/* line 31, resources/assets/fonts/sm-icon-font/smi.scss */

.smi-heart-regular:before {
	content: "\EA14";
}

/* line 32, resources/assets/fonts/sm-icon-font/smi.scss */

.smi-home:before {
	content: "\EA0F";
}

/* line 33, resources/assets/fonts/sm-icon-font/smi.scss */

.smi-instagram-brands:before {
	content: "\EA10";
}

/* line 34, resources/assets/fonts/sm-icon-font/smi.scss */

.smi-linkedin-in-brands:before {
	content: "\EA11";
}

/* line 35, resources/assets/fonts/sm-icon-font/smi.scss */

.smi-magnifying-glass-regular:before {
	content: "\EA1A";
}

/* line 36, resources/assets/fonts/sm-icon-font/smi.scss */

.smi-moon-regular:before {
	content: "\EA13";
}

/* line 37, resources/assets/fonts/sm-icon-font/smi.scss */

.smi-phone-regular:before {
	content: "\EA20";
}

/* line 38, resources/assets/fonts/sm-icon-font/smi.scss */

.smi-pinterest-brands:before {
	content: "\EA15";
}

/* line 39, resources/assets/fonts/sm-icon-font/smi.scss */

.smi-quote-left-solid:before {
	content: "\EA16";
}

/* line 40, resources/assets/fonts/sm-icon-font/smi.scss */

.smi-square-check-solid:before {
	content: "\EA17";
}

/* line 41, resources/assets/fonts/sm-icon-font/smi.scss */

.smi-star-half-stroke-regular:before {
	content: "\EA18";
}

/* line 42, resources/assets/fonts/sm-icon-font/smi.scss */

.smi-star-regular:before {
	content: "\EA19";
}

/* line 43, resources/assets/fonts/sm-icon-font/smi.scss */

.smi-star-solid:before {
	content: "\EA1A";
}

/* line 44, resources/assets/fonts/sm-icon-font/smi.scss */

.smi-times-light:before {
	content: "\EA1B";
}

/* line 45, resources/assets/fonts/sm-icon-font/smi.scss */

.smi-twitter-brands:before {
	content: "\EA1C";
}

/* line 46, resources/assets/fonts/sm-icon-font/smi.scss */

.smi-user-regular:before {
	content: "\EA1D";
}

/* line 47, resources/assets/fonts/sm-icon-font/smi.scss */

.smi-whatsapp-brands:before {
	content: "\EA1E";
}

/* line 61, resources/assets/fonts/sm-icon-font/smi.scss */

.smi-water-ladder-regular:before {
	content: "\EA2C";
}

/* line 62, resources/assets/fonts/sm-icon-font/smi.scss */

.smi-water-light:before {
	content: "\EA2D";
}

/*@font-face {
	font-family: 'Font Awesome 5 Pro';
	font-style: normal;
	font-weight: 300;
	font-display: block;
	src: url("https://r.v-office.com/fonts/fa-light-300.eot");
	src: url("https://r.v-office.com/fonts/fa-light-300.eot?#iefix") format("embedded-opentype"),
		url("https://r.v-office.com/fonts/fa-light-300.woff2") format("woff2"),
		url("https://r.v-office.com/fonts/fa-light-300.woff") format("woff"),
		url("https://r.v-office.com/fonts/fa-light-300.ttf") format("truetype"),
		url("https://r.v-office.com/fonts/fa-light-300.svg#fontawesome") format("svg");
}

@font-face {
	font-family: 'Font Awesome 5 Pro';
	font-style: normal;
	font-weight: 400;
	font-display: block;
	src: url("https://r.v-office.com/fonts/fa-regular-400.eot");
	src: url("https://r.v-office.com/fonts/fa-regular-400.eot?#iefix") format("embedded-opentype"),
		url("https://r.v-office.com/fonts/fa-regular-400.woff2") format("woff2"),
		url("https://r.v-office.com/fonts/fa-regular-400.woff") format("woff"),
		url("https://r.v-office.com/fonts/fa-regular-400.ttf") format("truetype"),
		url("https://r.v-office.com/fonts/fa-regular-400.svg#fontawesome") format("svg");
} */

.input-group-text {
	border-right: 0;
	background-color: #fff;
}

.input-group-text {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 0.75rem 0.75rem;
	margin-bottom: 0;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #293771;
	text-align: center;
	white-space: nowrap;
	background-color: transparent;
	border: 1px solid #293771;
	border-radius: 0;
}

.form-control {
	border-radius: 0;
}

.dlg {
	z-index: 9999 !important;
}

.fa-hotel:before {
	content: "\f594" !important;
}

.modal-backdrop {
	@media (max-width: 767px) {
		background: transparent !important;
		z-index: unset !important;
	}
}

#lastviewed-units {
	.heading1 {
		font-size: 34px;
		margin: 0 0 30px 0;
		text-align: center;

		@media (max-width: 767px) {
			font-size: 24px;
			margin: 0 0 10px 0;
			text-align: left;
		}
	}

	.single-units {
		@media (max-width: 767px) {
			margin-bottom: 30px;
		}
	}

	.container-fluid {
		padding: 50px 0 !important;
		background: #f4f4f4;

		@media (max-width: 767px) {
			padding: 20px 0 !important;
		}
	}
}

.facility-swimmingpool-info {
	padding: 80px 15px;
	margin-top: 80px;
	background-color: rgba(151, 194, 228, .25);

	@media (max-width: 767px) {
		background: transparent;
		padding: 0;
		margin-top: 50px;
	}

	.swimming-inner {
		display: flex;
		flex-wrap: wrap;

		.left {
			width: 50%;

			@media (max-width: 991px) {
				width: 100%;
			}

			img {
				width: 100%;
				height: 380px;
				object-fit: cover;

				@media (max-width: 767px) {
					height: 250px;
				}
			}

			.owl-nav {
				.owl-prev {
					position: absolute;
					left: -20px;
					top: 45%;
					width: 45px;
					height: 45px;
					background: #293771;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 20px;
					color: #fff;
					padding: 0 0 11px 0 !important;

					@media (max-width: 767px) {
						left: 0;
						width: 40px;
						height: 40px;
					}

					span {
						display: inline-block;
						font-size: 40px;
						line-height: 10px;
					}
				}

				.owl-next {
					position: absolute;
					right: -20px;
					top: 45%;
					width: 45px;
					height: 45px;
					background: #293771;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 20px;
					color: #fff;
					padding: 0 0 11px 0 !important;

					@media (max-width: 767px) {
						right: 0;
						width: 40px;
						height: 40px;
					}

					span {
						display: inline-block;
						font-size: 40px;
						line-height: 10px;
					}
				}
			}
		}

		.right {
			width: 50%;
			background: #fff;
			padding: 40px 70px;
			margin: 15px 0 15px;

			@media (max-width: 991px) {
				width: 100%;
				margin: 0;
				padding: 15px 0;
			}

			@media all and (min-width: 768px) and (max-width: 1199px) {
				padding: 40px 10px 40px 50px;
			}

			h3 {
				color: #161616;
				font-size: 24px;
				font-weight: 600;
				margin: 0;
			}

			h4 {
				font-size: 36px;
				color: #161616;
				font-weight: 500;
				margin-bottom: 20px;

				@media (max-width: 767px) {
					font-size: 28px;
					margin: 0px;
				}

				@media all and (min-width: 992px) and (max-width: 1199px) {
					font-size: 32px;
					margin-top: 10px;
				}
			}

			ul {
				margin: 30px 0 40px 0;

				@media (max-width: 767px) {
					margin: 15px 0 25px 0;
				}

				li {
					position: relative;
					font-size: 16px;
					line-height: 24px;
					padding-left: 20px;
					margin-bottom: 5px;

					&:before {
						content: "\f14a";
						position: absolute;
						font-family: "Font Awesome 5 Pro";
						font-weight: 900;
						color: #c6a372;
						left: 0;
						top: 1px;
					}
				}
			}

			h6 {
				margin: 0;

				@media (max-width: 767px) {
					text-align: center;
				}
			}

			.btn-secondary {
				max-width: 235px;
			}
		}
	}
}

#similar-units {
	padding: 20px 0;

	.heading1 {
		text-align: left;
		color: #161616;
		font-size: 26px;
	}
}


/*.active {
	color: red;
	background-color: red;
}

#nav {
	position: fixed;
	top: 400px;
	right: 50%;
}

section {
	min-height: 500px;
}*/