.unit-navbar-v1 {
	background-color: var(--color-white);
	position: -webkit-sticky;
	position: sticky;
	z-index: 14;
	top: 170px;
	text-align: left;
	margin-top: -30px;
	margin-bottom: 15px;


	.fl-menu-itm {
		padding: 20px 10px;
		color: var(--unit-navbar-font-color-item);
		display: inline-block;
		text-transform: uppercase;
		font-size: var(--unit-navbar-font-size-item);
		border-bottom: 2px solid #fff;
		text-decoration: none;

		&:hover {
			/* color: var(--unit-navbar-font-color-item-hover);
			background-color: var(--unit-navbar-bg-color-item-hover); */
			/* border-bottom: 2px solid #c6a372;
			text-decoration: none;
			color: #c6a372; */
		}

		&.active {
			border-bottom: 2px solid #c6a372;
			text-decoration: none;
			color: #c6a372;
		}

		@media (max-width:1200px) {
			font-size: var(--font-size-md);
		}

	}


	@media (max-width:992px) {
		display: none;
	}

	.unit-nav-tabs {
		width: 100%;
		border-bottom: 1px solid #ccc;
	}
}