.unit-properties-v1 {
	padding-bottom: 30px;
	border-bottom: 1px solid #ccc;

	@media (max-width: 767px) {
		margin-bottom: 40px;
	}

	.widget-block {
		display: none;
	}

	.widget-header {
		display: flex;
		padding: 0;
		color: #161616;
		text-decoration: none;

		.title1 {
			padding: 0 0 20px;
			color: #161616;
			font-size: 26px;
		}

		a {
			text-decoration: none;
			font-size: 16px;
		}

		span {
			display: flex;
			align-items: center;
			padding-bottom: 15px;
			margin-left: 20px;
			font-size: 16px;
		}
	}

	.unit-section {
		margin-bottom: 0px !important;
	}

	.widget-header {
		display: flex;
		align-items: center;

		a {
			font-size: 16px;
			margin: 0 0 0 20px;
			padding-top: 5px;
		}
	}

	.unit-section {
		&.showFull {
			.widget-block {
				display: block;
			}
		}
	}


	.title {

		font-weight: 800;
		font-size: 18px;
		color: #161616;
	}

	.tick-li {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 20px;

		@media (max-width:992px) {
			font-size: var(--font-size-md);
		}

		li {
			width: 33%;
			padding-left: 30px;
			padding-right: 15px;
			min-height: 30px;
			position: relative;

			@media (max-width:992px) {
				width: 50%;
			}

			@media (max-width:480px) {
				width: 100%;
			}

			&:before {
				content: "\f00c";
				font-family: "Font Awesome 5 Pro";
				-webkit-font-smoothing: antialiased;
				font-weight: 400;
				display: inline-block;
				position: absolute;
				left: 0;
				top: 0;
				color: #161616;
			}
		}
	}

	.showLess {
		position: relative;

		&.showFull {
			.widget {
				display: block;
			}


			.hide-show-btn {

				&:after {
					content: "\f106";
				}
			}
		}

		.widget {
			display: none;
		}

		.first {
			display: block;
		}

		.hide-show-btn {
			position: absolute;
			bottom: -14px;
			left: 50%;
			transform: translate(-50%, 0);
			padding: 5px 15px;
			background-color: var(--white);
			display: inline-block;
			border: 1px solid var(--btn-border-color-hide-show);
			font-size: var(--font-size-md);

			&:focus,
			&:hover {
				text-decoration: none;
			}

			&:after {
				content: "\f107";
				margin-left: 10px;
				margin-left: 5px;
				font-family: fontawesome;
			}
		}
	}
}