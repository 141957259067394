.search-unit-v3 {

	.unit-item {
		background-color: #fff;
		padding: 10px;
		/* border: 1px solid var(--color-grey-normal); */
		border: none !important;
		transition: all 0.2s;
		height: 100%;
		background: #fff;

		&:hover {
			border: 1px solid var(--color-primary);
			/* transform: scale(1.015); */

			.owl-carousel {
				.owl-nav {
					button {
						visibility: visible;
						opacity: 1;
					}
				}
			}

			.go-to-unit {
				opacity: 1;
			}
		}

		.owl-carousel {
			overflow: hidden;

			.owl-nav {
				button {
					width: 25px;
					height: 25px;
					top: 107px;
					position: absolute;
					cursor: pointer;
					visibility: hidden;
					opacity: 0;
					-webkit-transition: visibility 0s, opacity .5s linear;
					transition: visibility 0s, opacity .5s linear;
					color: #000;
					color: #161616;
					font-size: 26px;
					line-height: 1px;
					text-align: center;
					background-color: hsla(0, 0%, 100%, .7);
				}

				.owl-prev {
					left: 10px;

					span {
						display: inline-block;
						padding-bottom: 8px;
						padding-right: 1px;
					}
				}

				.owl-next {
					right: 10px;

					span {
						display: inline-block;
						padding-bottom: 8px;
						padding-left: 1px;
					}
				}
			}

			.owl-dots {
				position: absolute;
				width: 70%;
				bottom: 10px;
				padding: 0;
				margin: 0;
				list-style: none;
				text-align: center;
				line-height: 1;
				left: 50%;
				transform: translateX(-50%);

				.owl-dot {
					background-color: #fff;
					width: 6px !important;
					height: 6px !important;
					opacity: .25;
					margin: 0 1px !important;
					border-radius: 50%;

					&.active {
						opacity: 1;
					}
				}
			}
		}

		.unit-container {
			display: flex;
			/* flex-direction: column; */
			height: 100%;
			background: #fff;

			@media (max-width: 992px) {
				flex-wrap: wrap;
			}

			.img-col {
				position: relative;
				width: 40%;
				height: 250px;


				@media (max-width: 992px) {
					width: 100%;
					height: 220px;
				}

				.img-content {
					height: 100%;

					.owl-carousel,
					.owl-stage-outer,
					.owl-stage,
					.owl-item,
					.item {
						height: 100%;
					}
				}

				.fav-icon {
					position: absolute;
					left: 10px;
					top: 10px;
					color: #fff;
					cursor: pointer;
					z-index: 2;
					font-size: 24px;
				}

				.rating {
					position: absolute;
					right: 0px;
					bottom: 0px;
					padding: 7px 7px 7px 12px;
					z-index: 2;
					background-color: rgba(255, 255, 255, 0.7);
					font-size: var(--font-size-md);

					.fa {
						color: var(--color-primary);
					}

					.ratings {
						display: inline-block;
						margin-left: 3px;
					}

				}

				.price {
					position: absolute;
					background-color: var(--color-secondary);
					text-align: center;
					top: 20px;
					right: -10px;
					padding: 0px 10px;
					z-index: 2;
					color: #fff;

					.h3 {
						font-size: 18px;
						color: #fff;
						line-height: 28px;
					}


					.main-price {
						&.h5 {
							color: var(--color-grey-dark);
							text-decoration: line-through;

						}
					}


				}



				img {
					width: 100%;
					object-fit: cover;
					height: 100%;
					object-position: center center;
				}

				.img-content {

					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					overflow: hidden;

				}

			}

			.info-col {
				width: 60%;
				padding-left: 30px;
				position: relative;

				@media (max-width: 992px) {
					width: 100%;
					padding-left: 0;
					padding: 10px 15px;
				}

				.dlg {
					@media (max-width: 767px) {
						padding: 15px !important;
					}
				}

				.box {
					height: 570px;
					width: 600px;
					border-radius: 0;

					.header {
						h4 {
							margin: 8px 0;
						}
					}

					ul {
						list-style: disc;
						padding-left: 20px;
						margin-bottom: 10px;
						color: #161616;
						font-size: 16px;
						font-weight: 400;
					}

					.btn-primary {
						background-color: #fff;
						background-color: var(--btn-secondary-bg-color);
						color: #293771;
						color: var(--btn-secondary-font-color);
						border-color: #293771;
						border-color: var(--btn-secondary-border-color);
						padding: 10px 25px;
						margin: 0;
						max-width: unset;
						width: auto;

						&:hover {
							background: var(--btn-primary-bg-color) !important;
							color: #fff;
						}
					}
				}

				.unit-title {
					padding-top: 10px;
					display: flex;
					align-items: center;


					@media (max-width: 767px) {
						align-items: unset;
					}

					.left-title {
						width: 85%;

						@media (max-width: 1199px) {
							width: 75%;
						}
					}

					.rating {
						width: 21%;
						text-align: right;

						@media (max-width: 1199px) {
							width: 25%;
							text-align: left;
							padding-top: 10px;
						}

						span {
							color: #161616;
							font-size: 12px;
						}

						.ratings {
							display: flex;
							align-items: center;
							color: #97C2E4;
							font-size: 12px;
							justify-content: flex-end;
						}
					}

					.h3 {
						font-size: 24px;
						font-weight: 600;
						color: #161616;
						line-height: 32px;

						@media (max-width: 767px) {
							line-height: 36px;
						}
					}
				}

				.offer-price-box {
					.main-price {
						text-decoration: line-through;
						padding-right: 5px;
					}
				}

				.price {
					color: var(--color-secondary);
					font-family: var(--h3-font-family);

					@media (max-width: 767px) {

						width: 45%;
						text-align: right;
					}

					span {
						font-size: 26px;
						font-weight: 800;
					}

					.small {
						font-size: 26px;
						font-weight: 800;
					}

					.h3 {
						font-size: 26px;
						font-weight: 800;
						color: #C6A372;
					}

					.price-info-small {
						display: block;
						font-size: 12px;
						font-style: italic;
						font-weight: 400;
						line-height: 15px;

						@media (max-width: 767px) {
							display: none;
						}
					}

				}


			}

			.type {
				font-size: 16px;
				color: #161616;
				font-weight: 400;
			}

			.place {
				font-size: var(--font-size-md);

				.fa {
					color: var(--color-grey-dark);
				}
			}

			.unit-highlights {
				padding-top: 10px;

				>.fa,
				>i {
					padding-left: 5px;
					font-size: 20px;
					color: #c6a372;
				}

				.fa-paw.cross {
					position: relative;

					&:after {
						content: "";
						width: 2px;
						height: 28px;
						position: absolute;
						background-color: #c6a372;
						transform: rotate(-45deg);
						left: 15px;
						top: -4px;
					}
				}
			}

			.flex-middle {
				flex-grow: 1;
				padding: 10px 0px;

				@media (max-width: 767px) {
					display: flex;
					flex-wrap: wrap;
					align-items: flex-end;
				}
			}

			.properties-row {

				display: flex;
				padding-right: 100px;

				@media (max-width: 767px) {
					padding-right: 0;
					width: 55%;
				}

				.unit-properties {
					width: 50%;
					font-size: 16px;
					color: #161616;
					line-height: 24px;
					margin-bottom: 10px;

					@media (max-width: 767px) {
						width: 100%;
						margin-bottom: 0;

					}
				}
			}

			.flex-bottom {
				position: absolute;
				top: 40%;
				right: -10px;
			}

			.alternatives {
				padding-top: 10px;

				.altheading {
					font-weight: 700;
					font-size: var(--font-size-md);
				}

				a {
					color: #161616;
					font-size: 14px;
					line-height: 28px;


					&:hover {
						text-decoration: underline;
					}
				}

				.fa {
					font-size: var(--font-size-sm);
					color: #161616;
				}
			}

		}

		.go-to-unit {
			height: 50px;
			display: flex;
			width: 50px;
			align-items: center;
			justify-content: center;
			text-align: center;
			background-color: #293771;
			background-color: #97c2e4;
			-webkit-transition: all .5s;
			transition: all .5s;
			opacity: 0;
			text-decoration: none !important;

			.fa {
				color: var(--color-white);
				line-height: 28px;
			}

		}

		.offer-box-main {
			width: 100%;
		}

		.offer-box {
			position: relative;
			margin-top: 10px;
			color: var(--color-primary);
			padding: 0;
			text-align: left;
			font-weight: 700;

			a {
				color: rgb(198, 163, 114);
				max-width: 300px;
				display: inline-block;
				width: 100%;
				text-align: center;
				font-size: 16px;
				text-decoration: none;
				background: transparent;
				border-width: 1px;
				border-style: solid;
				border-color: rgb(198, 163, 114);
				border-image: initial;
				padding: 10px 15px;

				@media (max-width: 767px) {
					width: 100%;
					max-width: 100%;
				}
			}

			.btn-primary {
				color: #c6a372;
				max-width: 300px;
				display: inline-block;
				width: 100%;
				text-align: center;
				font-size: 16px;
				text-decoration: none;
				background: transparent;
				border: 1px solid #c6a372;
				-webkit-border-image: initial;
				-o-border-image: initial;
				border-image: none;
				border-image: initial;
				padding: 10px 15px;
				margin-top: 15px;
				font-weight: 800;

				@media (max-width: 767px) {
					width: 100%;
					max-width: 100%;
					margin: 0;
				}
			}

		}
	}

}

.popover {
	border-radius: 0;
	background-color: var(--color-white);
	border: 1px solid #C0C0C0;
	font-size: var(--font-size-md);
	box-shadow: inherit;
	width: 100%;
	max-width: 520px;

	.popover-header {
		border-radius: 0;
		background-color: var(--color-grey-light);
		margin-bottom: 10px;
		padding: 10px 10px;
		font-size: var(--font-size-md);
		font-weight: normal;
	}

	.arrow {
		&:after {
			border-top-color: #C0C0C0 !important;
		}
	}

	.popover-body {
		padding: 10px;


		ul {
			overflow-y: scroll;
			height: 180px;
			padding-right: 10px;


			li {
				position: relative;
				margin-bottom: 2px;
				padding-left: 14px;
				list-style: none;
				font-size: var(--font-size-md);
				font-family: var(--font-family-main);
				margin-bottom: 3px;

				&:before {
					background-color: var(--color-primary);
					width: 5px;
					height: 5px;
					position: absolute;
					content: "";
					left: 0;
					top: 6px;
					border-radius: 50%;

				}
			}
		}
	}
}