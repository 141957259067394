.unit-offers-v1 {
	.unit-offers-inner {
		padding: 50px 0 30px 0;
		border-bottom: 1px solid #ccc;
		margin-bottom: 0;
	}

	.XY {

		.unit-offer {



			&:after {
				background: #8ac4e5;
				width: 150px;
				height: 80px;
				position: absolute;
				right: -60px;
				top: -22px;
				display: flex;
				align-items: center;
				justify-content: center;
				transform: rotate(45deg);
				padding: 30px 0 0 0;
				content: " ";
			}

			&:before {
				content: "\f2dc";
				font-family: Font Awesome\ 5 Pro;
				font-weight: 900;
				position: absolute;
				color: #2c5685;
				font-size: 22px;
				right: 16px;
				top: 10px;
				z-index: 1;
			}
		}

	}

	.PERCENT {
		.unit-offer {
			&:after {
				background: #cc9d68;
				width: 150px;
				height: 80px;
				position: absolute;
				right: -60px;
				top: -22px;
				display: flex;
				align-items: center;
				justify-content: center;
				transform: rotate(45deg);
				padding: 30px 0 0 0;
				content: " ";
			}

			&:before {
				content: "\f295";
				font-family: Font Awesome\ 5 Pro;
				font-weight: 900;
				position: absolute;
				color: #2c5685;
				font-size: 22px;
				right: 16px;
				top: 10px;
				z-index: 1;
				color: #fff;
			}
		}

	}

	.offer-name {
		font-size: 26px;
		font-weight: 500;

		@media (max-width: 767px) {
			padding-right: 40px;
			font-size: 22px;
		}
	}

	.unit-offer {
		padding: 30px;
		background-color: var(--color-white);
		position: relative;
		margin-bottom: 30px;
		border: 0px solid var(--color-grey-normal);
		background: #f4f4f4;
		position: relative;
		overflow: hidden;

		.offer-minstay {
			font-size: 16px;
			color: #161616;

		}

		.offer-minstay-xy {
			font-size: 16px;
			color: #161616;

		}

		.offer-condition {
			font-size: var(--font-size-md);
		}

		.offer-banner {
			position: absolute;
			background-color: var(--color-red);
			color: var(--color-white);
			top: -13px;
			left: -8px;
			padding: 2px 10px;
			text-transform: uppercase;
			font-size: var(--font-size-sm);
			border-radius: 5px;
			box-shadow: 1px 0 3px 0 rgb(0 0 0 / 35%);
			display: none;
		}


	}
}