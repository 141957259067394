.unit-image-gallery-v3 {
	margin-right: -5px;
	margin-left: -5px;
	margin-top: -5px;

	.visible-print {
		display: none !important;
	}

	.unitGallery {
		position: relative;

		&.gal-video {
			a {
				&:nth-child(2) {
					width: 75%;
					float: left;
					display: block;
					height: 660px;
					overflow: hidden;

					@media (max-width: 767px) {
						width: 100%;
						height: 300px;
					}

					@media all and (min-width: 768px) and (max-width: 992px) {
						height: 420px;
					}
				}

				&:nth-child(5) {

					&:before,
					&:after {

						@media (max-width: 767px) {
							display: none;
						}
					}
				}

				&:nth-child(6) {
					position: relative;

					@media (max-width: 767px) {
						display: block;
						width: 50%;
						height: 140px;
						display: block;
						float: left;
					}

					&:before {


						@media (max-width: 767px) {
							content: "+ mehr";
							position: absolute;
							left: 0;
							right: 0;
							top: 50%;
							bottom: 0;
							text-align: center;
							width: 100%;
							color: #fff;
							font-weight: 600;
							font-size: var(--font-size-md);
							margin-top: -10px;
							z-index: 1;
							padding: 0 10px;
							font-size: 20px;
						}

					}

					&:after {

						@media (max-width: 767px) {
							content: "";
							position: absolute;
							left: 5px;
							right: 5px;
							top: 5px;
							bottom: 5px;
							background: rgba(0, 0, 0, .5);
							z-index: 0;
						}

						@media (max-width: 767px) {
							top: 5px;
							bottom: 5px;
						}
					}

				}
			}
		}

		.video-bl {
			width: 25%;
			float: right;
			display: block;
			height: 220px;
			position: relative;

			iframe {
				width: calc(100% - 10px);
				height: calc(100% - 10px);
				position: absolute;
				margin: 5px;
			}

			@media (max-width: 767px) {
				width: 100%;
				height: 295px;
			}

			@media all and (min-width: 768px) and (max-width: 992px) {
				height: 140px;
			}

		}

		.fav-icon {
			position: absolute;
			top: 20px;
			left: 20px;
			color: #fff;
			font-size: 22px;
			cursor: pointer;
		}

		a {
			display: none;
			padding: 5px;
			height: 150px;

			&:nth-child(2),
			&:nth-child(3),
			&:nth-child(4) {
				height: 220px;
			}

			@media (max-width: 767px) {
				padding: 5px;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			&:nth-child(1) {
				width: 75%;
				float: left;
				display: block;
				height: 660px;
				overflow: hidden;

				@media (max-width: 767px) {
					width: 100%;
					height: 300px;
				}

				@media all and (min-width: 768px) and (max-width: 992px) {
					height: 420px;
				}
			}

			&:nth-child(2),
			&:nth-child(3),
			&:nth-child(4) {
				width: 25%;
				float: left;
				display: block;

				@media (max-width: 767px) {
					width: 50%;
					height: 140px;
				}

				@media all and (min-width: 768px) and (max-width: 992px) {
					height: 140px;
				}
			}

			&:nth-child(5) {
				@media (max-width: 767px) {
					width: 50%;
					height: 140px;
					display: block;
					float: left;
				}
			}

			/* &:nth-child(4) {
				clear: both;

				@media (max-width: 767px) {
					clear: none;
					height: 140px;
				}
			} */

			/* &:nth-child(4),
			&:nth-child(5),
			&:nth-child(6),
			&:nth-child(7),
			&:nth-child(8) {
				display: block;
				width: 20%;
				float: left;

				@media (max-width: 767px) {
					width: 50%;
					height: 140px;
				}
			} */

			&:nth-child(4) {
				position: relative;

				&:before {
					content: "+ mehr";
					position: absolute;
					left: 0;
					right: 0;
					top: 50%;
					bottom: 0;
					text-align: center;
					width: 100%;
					color: #fff;
					font-weight: 600;
					font-size: var(--font-size-md);
					margin-top: -10px;
					z-index: 1;
					padding: 0 10px;
					font-size: 20px;

					@media (max-width: 767px) {
						display: none;
					}

				}

				&:after {
					content: "";
					position: absolute;
					left: 5px;
					right: 5px;
					top: 5px;
					bottom: 5px;
					background: rgba(0, 0, 0, .5);
					z-index: 0;

					@media (max-width: 767px) {
						display: none;
					}

					@media (max-width: 767px) {
						top: 5px;
						bottom: 5px;
					}
				}

			}

			&:nth-child(5) {
				position: relative;

				&:before {


					@media (max-width: 767px) {
						content: "+ mehr";
						position: absolute;
						left: 0;
						right: 0;
						top: 50%;
						bottom: 0;
						text-align: center;
						width: 100%;
						color: #fff;
						font-weight: 600;
						font-size: var(--font-size-md);
						margin-top: -10px;
						z-index: 1;
						padding: 0 10px;
						font-size: 20px;
					}

				}

				&:after {

					@media (max-width: 767px) {
						content: "";
						position: absolute;
						left: 5px;
						right: 5px;
						top: 5px;
						bottom: 5px;
						background: rgba(0, 0, 0, .5);
						z-index: 0;
					}

					@media (max-width: 767px) {
						top: 5px;
						bottom: 5px;
					}
				}

			}
		}
	}

}