.search-result-header-v2 {
	.srt-bl-top {

		.flex-align-center {
			@media (max-width:992px) {
				display: block !important;
				text-align: right;
			}

			@media (max-width: 767px) {
				display: none !important;
			}


			.btn-filter {
				font-size: var(--font-size-md);

				@media (min-width:993px) {
					border-right: 1px solid var(--color-grey-dark);
					padding-right: 8px;
					margin-right: 5px;
					margin-bottom: 0;
					top: 0;
				}

				display: inline-block;
				margin-bottom: 5px;
				color: var(--color-secondary);
				position: relative;
				top:-6px;

				&:hover {
					color: var(--color-primary);
				}
			}
		}
	}

	.srt-bl-top {
		margin: 10px 0;
	}

	.sort-price-new .form-control {
		background-position: 93% 15px;
	}



}