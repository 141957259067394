.unit-card-v2 {

	&:hover {
		.content {
			.go-to-unit {
				opacity: 1;
				visibility: visible;
			}
		}

	}

	.h5 {
		font-size: 24px;
		font-weight: 600;
		color: #161616;
		line-height: 32px;
		margin: 0;
	}

	.region-type {
		font-size: 16px;
		color: #161616;
		margin: 0 0 15px 0;
	}

	.rules {
		display: none;
	}

	.content-boxes {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-end;
		justify-content: space-between;

		.comodities {
			width: 73%;
		}

		.price {
			color: var(--color-secondary);
			font-family: var(--h3-font-family);
			font-weight: 800;
			font-size: 22px;
			width: 27%;

			.price-info-small {
				display: block;
				font-size: 12px;
				font-weight: 400;
				font-style: italic;
			}
		}
	}

	.unit-title {
		display: flex;
		flex-wrap: wrap;

		.left-title {
			width: 80%;
		}

		.rating {
			width: 20%;
			padding: 10px 0 0 0;

			span {
				font-size: 12px;
				color: #161616;
			}

			.ratings {
				display: flex;
				align-items: center;
				color: #97c2e4;
				font-size: 12px;

				i {
					padding-right: 0;
				}
			}
		}
	}

	.content {
		padding: 15px;
		display: flex;
		flex-direction: column;
		min-height: 160px;
		justify-content: space-between;
		border: 0px solid var(--color-grey-normal);
		background-color: var(--white);
		position: relative;

		.unit-name {
			font-size: 24px;
			font-weight: 600;
			color: #161616;
			line-height: 32px;
			margin: 0;
		}

		/* .region-type {
			margin-top: -22px;
		}

		.fa {
			padding-right: 5px;
		}

		.fa-map-marker-alt {
			padding-left: -10px;
		} */

		.flex-bottom {
			position: absolute;
			top: 35%;
			right: 0;
		}

		.go-to-unit {
			height: 50px;
			display: flex;
			width: 50px;
			align-items: center;
			justify-content: center;
			text-align: center;
			background-color: #293771;
			background-color: #97c2e4;
			-webkit-transition: all .5s;
			transition: all .5s;
			opacity: 0;
			text-decoration: none !important;

			.fa {
				color: var(--color-white);
				line-height: 28px;
			}

		}

	}

	.comodities {
		margin-top: 0px;
		position: relative;

		.button-details {
			position: absolute;
			bottom: 0px;
			right: 0px;
			color: var(--color-secondary);
			font-family: Source Sans Pro, sans-serif;
			font-family: var(--h3-font-family);
			font-weight: 800;
			font-size: 22px;
		}
	}

	.btn {
		min-width: 120px;
		padding-top: 8px;
		padding-bottom: 8px;
	}

	.unit-highlights {
		padding-bottom: 10px;

		>.fa,
		>i {
			padding-left: 5px;
			font-size: 20px;
			color: #c6a372;
		}

		.fa-paw.cross {
			position: relative;

			&:after {
				content: "";
				width: 2px;
				height: 28px;
				position: absolute;
				background-color: #c6a372;
				transform: rotate(-45deg);
				left: 15px;
				top: -4px;
			}
		}
	}


	.comodity {}

	.image-wrap {
		position: relative;

		&:hover {
			img {
				filter: brightness(0.7);
			}
		}

		img {
			width: 100%;
			transition: 0.5s;

		}



		.stars {
			position: absolute;
			bottom: 10px;
			right: 10px;
			color: var(--color-stars);
			background-color: white;
			padding: 9px;
			border-radius: 5px;
			display: flex;
			align-items: center;
		}

		.rules {
			position: absolute;
			bottom: 10px;
			right: 10px;
			background-color: white;
			padding: 5px;
			border-radius: 5px;
			color: var(--font-color-main);
		}

		.favorite {
			position: absolute;
			top: 10px;
			left: 10px;
			color: white;
			font-size: 24px;
		}
	}


	.ribbon {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		overflow: hidden;
		width: 75px;
		height: 75px;
		text-align: right;
	}

	.ribbon span {
		font-size: 13px;
		font-weight: bold;
		color: #fff;
		text-transform: uppercase;
		text-align: center;
		line-height: 20px;
		transform: rotate(-45deg);
		-webkit-transform: rotate(-45deg);
		width: 100px;
		display: block;
		background: var(--color-red);
		box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
		position: absolute;
		top: 19px;
		left: -21px;
	}

	&.facility-unit-card-v2 {
		.content {
			border: 1px solid #ccc;
		}

		.h5 {
			@media all and (min-width: 768px) and (max-width: 991px) {
				font-size: 22px;
			}
		}

		.unit-title {
			.left-title {
				padding-right: 5px;

				@media all and (min-width: 768px) and (max-width: 1199px) {
					width: 75%;
				}
			}

			.rating {
				@media all and (min-width: 768px) and (max-width: 1199px) {
					width: 25%;
				}
			}
		}
	}
}