.footer-newsletter {
	background-color: #C6A372;
	color: #fff;
	margin-top: 80px;
}

/* line 128, resources/assets/styles/layouts/_footer.scss */

.footer-newsletter p {
	font-weight: 600;
}

/* line 132, resources/assets/styles/layouts/_footer.scss */

.footer-newsletter .form-control,
.footer-newsletter .search-form .search-field,
.search-form .footer-newsletter .search-field,
.footer-newsletter .input-group-text {
	border-color: #fff;
}

/* line 136, resources/assets/styles/layouts/_footer.scss */

.footer-newsletter .form-control>span,
.footer-newsletter .search-form .search-field>span,
.search-form .footer-newsletter .search-field>span,
.footer-newsletter .input-group-text>span {
	color: #161616;
}

.footer-newsletter .form-control {
	height: 50px;
}

.footer-newsletter .input-group-text {
	background-color: var(--color-white);
}

.footer-newsletter {

	.smi-envelope-regular {
		font-size: 1.25rem;
		line-height: 1.0rem;
	}

}

#footer-main {
	background-color: #161616;
	color: #fff;
}

/* line 146, resources/assets/styles/layouts/_footer.scss */

#footer-main a {
	color: #fff;
}

/* line 152, resources/assets/styles/layouts/_footer.scss */

#footer-main address {
	margin-bottom: 3rem;
}

/* line 155, resources/assets/styles/layouts/_footer.scss */

#footer-main address .lead {
	font-size: 1rem;
	margin-bottom: 1.5rem;
}

/* line 160, resources/assets/styles/layouts/_footer.scss */

#footer-main address a {
	margin-top: 0.5rem;
	display: inline-block;
	font-weight: 600;
}

/* line 169, resources/assets/styles/layouts/_footer.scss */

#footer-main-meta-nav .meta-navigation {
	padding: 0;
	padding-bottom: 2rem;
}

/* line 174, resources/assets/styles/layouts/_footer.scss */

#footer-main-meta-nav .menu-item {
	display: inline-block;
	margin-right: 0.25rem;
}

/* line 178, resources/assets/styles/layouts/_footer.scss */

#footer-main-meta-nav .menu-item::before {
	content: '|';
	padding-right: 0.25rem;
}

/* line 184, resources/assets/styles/layouts/_footer.scss */

#footer-main-meta-nav .menu-item:first-child::before {
	display: none;
}

/* line 189, resources/assets/styles/layouts/_footer.scss */

#footer-main-meta-nav .menu-item.menunewline {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 100%;
	flex: 0 0 100%;
}

/* line 191, resources/assets/styles/layouts/_footer.scss */

#footer-main-meta-nav .menu-item.menunewline::before {
	display: none;
}

@media (min-width: 768px) {
	/* line 199, resources/assets/styles/layouts/_footer.scss */

	#footer-menu-nav .d-md-none {
		display: block !important;
	}
}

/* line 206, resources/assets/styles/layouts/_footer.scss */

#footer-menu-nav a:hover,
#footer-menu-nav a:focus {
	text-decoration: underline;
}

/* line 212, resources/assets/styles/layouts/_footer.scss */

#footer-menu-nav .nav-item {
	margin-bottom: 1.5rem;
}

/* line 215, resources/assets/styles/layouts/_footer.scss */

#footer-menu-nav .nav-item>a {
	font-weight: 600;
}

/* line 220, resources/assets/styles/layouts/_footer.scss */

#footer-menu-nav .nav-link {
	padding: 0;
	margin-bottom: 0.5rem !important;
}

/* line 225, resources/assets/styles/layouts/_footer.scss */

#footer-menu-nav .submenu {
	list-style: none;
	padding-left: 0;
}

/* line 230, resources/assets/styles/layouts/_footer.scss */

#footer-menu-nav .submenu-link {
	display: inline-block;
	padding: 0.25rem 0 0;
}

/* line 236, resources/assets/styles/layouts/_footer.scss */

#footer-main-social-media {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

/* line 239, resources/assets/styles/layouts/_footer.scss */

#footer-main-social-media a {
	color: #C6A372;
	font-size: 2rem;
	margin-right: 1rem;
}

/* line 244, resources/assets/styles/layouts/_footer.scss */

#footer-main-social-media a:hover,
#footer-main-social-media a:focus {
	color: #97C2E4;
}